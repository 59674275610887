import React from 'react'
import type { FC } from 'react'

const BrillantIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <g id="Brilhante" transform="translate(0 0.201)">
      <path
        id="Polygon_3"
        data-name="Polygon 3"
        d="M9.5,0,19,19H0Z"
        transform="translate(19 18.799) rotate(180)"
        fill="#fff"
      />
    </g>
  </svg>
)

export default BrillantIcon
