import type { FC } from 'react'
import React from 'react'

const DigitalMagazineSvg: FC = () => (
  <>
    <svg
      xmlns="http://www.w3.org/1999/xlink"
      width="32"
      height="37"
      viewBox="0 0 90.68 86.001"
    >
      <g
        id="Componente_17_1"
        data-name="Componente 17 – 1"
        transform="translate(2.5 2.532)"
        stroke="#FFFFFF"
        fill="#FFFFFF"
      >
        <path
          id="Caminho_16"
          data-name="Caminho 16"
          d="M50.584,88.253V157.39s-21.928-12.125-39.9,0V88.253C30.462,74.839,50.584,88.253,50.584,88.253Z"
          transform="translate(-10.684 -82.291)"
          fill="none"
          stroke="#FFFFFF"
          strokeLinejoin="round"
          strokeWidth="5px"
        />
        <g
          id="Retângulo_3148"
          data-name="Retângulo 3148"
          transform="translate(38.787 1.469)"
          fill="none"
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
        >
          <rect
            width="49.392"
            height="76.333"
            rx="9"
            stroke="none"
            fill="none"
            strokeWidth="5px"
          />
          <rect
            x="2.5"
            y="2.5"
            width="44.392"
            height="71.333"
            rx="6.5"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="5px"
          />
        </g>
        <g
          id="Retângulo_3149"
          data-name="Retângulo 3149"
          transform="translate(45.523 8.204)"
          fill="none"
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
        >
          <rect
            width="35.922"
            height="53.882"
            rx="6"
            stroke="none"
            fill="none"
            strokeWidth="5px"
          />
          <rect
            x="2.5"
            y="2.5"
            width="30.922"
            height="48.882"
            rx="3.5"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="5px"
          />
        </g>
        <path
          id="Caminho_17"
          data-name="Caminho 17"
          d="M42.157,123.4c-6.535-4.385-20.9-6.965-31.472-1.29"
          transform="translate(-6.385 -43.402)"
          fill="none"
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeWidth="5px"
        />
        <ellipse
          id="Elipse_17"
          data-name="Elipse 17"
          cx="3.368"
          cy="3.368"
          rx="3.368"
          ry="3.368"
          transform="translate(58.993 64.332)"
          stroke="#FFFFFF"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  </>
)

export default DigitalMagazineSvg
