import { styled } from 'src/gatsby-theme-stitches/config'

export const PreHeaderContainer = styled('div', {
  position: 'relative',
  width: '100%',
  background: '$primary',
  overflow: 'hidden',
  zIndex: '3',
  fontFamily: '$roboto',
  boxShadow: '0px -3px 6px #00000029',
})

export const SliderContainer = styled('div', {
  width: '100%',
  height: '2.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@sm': {
    height: '3.625rem',
  },
})

export const SliderText = styled('div', {
  width: '90%',

  p: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '.3125rem',
    fontSize: '.875rem',
    textAlign: 'center',
    color: '$white',

    '@sm': {
      flexDirection: 'column',
      gap: '0',
      fontSize: '0.75rem',
    },
  },
})
