import React from 'react'
import type { FC } from 'react'

const CreamyIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <g id="Cremoso" transform="translate(0.431 0.448)">
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(-0.431 -0.448)"
        fill="#fff"
      />
    </g>
  </svg>
)

export default CreamyIcon
