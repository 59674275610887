import { deleteCookie, getCookie } from './cookies'

export const logoutUser = async () => {
  const isUserColab = getCookie('isUserColab')

  if (isUserColab) {
    deleteCookie('isUserColab')
    deleteCookie('isColaborador')
  }

  return fetch(
    `/api/vtexid/pub/logout?scope=${window.location.hostname}&returnUrl=%2f`
  )
}
