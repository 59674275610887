import React from 'react'

const NewBoletoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 638"
    version="1.1"
    width="30px"
    height="31px"
  >
    <g id="#FFFFff">
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 119.99 60.68 C 126.49 60.31 133.00 60.57 139.50 60.50 C 139.50 176.33 139.51 292.17 139.50 408.00 C 133.00 408.00 126.50 408.00 120.00 408.00 C 120.00 292.23 120.01 176.45 119.99 60.68 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 321.96 60.59 C 328.47 60.40 334.98 60.54 341.50 60.50 C 341.51 176.33 341.49 292.16 341.51 407.99 C 335.00 408.01 328.50 408.00 322.00 408.00 C 321.97 292.19 322.06 176.39 321.96 60.59 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 351.01 60.50 C 363.84 60.50 376.67 60.49 389.50 60.50 C 389.51 176.33 389.49 292.16 389.51 407.99 C 376.67 408.01 363.84 407.99 351.00 408.00 C 351.00 292.16 350.99 176.33 351.01 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 562.50 60.50 C 569.00 60.57 575.51 60.31 582.01 60.68 C 581.99 176.45 582.01 292.23 582.00 408.00 C 575.49 408.00 568.99 408.01 562.49 407.99 C 562.51 292.16 562.49 176.33 562.50 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 619.99 60.68 C 626.49 60.30 632.99 60.57 639.50 60.50 C 639.51 176.33 639.49 292.16 639.51 407.99 C 633.00 408.01 626.50 408.00 620.00 408.00 C 619.99 292.23 620.01 176.46 619.99 60.68 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 43.02 60.68 C 46.34 60.47 49.67 60.46 53.00 60.50 C 53.00 223.33 53.00 386.17 53.00 549.00 C 49.67 549.00 46.35 549.00 43.03 549.00 C 43.03 386.23 43.03 223.45 43.02 60.68 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 62.49 548.99 C 62.52 386.16 62.48 223.33 62.50 60.50 C 65.70 60.50 68.89 60.50 72.08 60.50 C 72.17 223.33 72.10 386.17 72.12 549.00 C 68.91 549.00 65.70 549.00 62.49 548.99 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 101.00 60.50 C 104.17 60.50 107.33 60.50 110.50 60.50 C 110.51 176.33 110.50 292.17 110.50 408.00 C 107.33 408.00 104.17 408.00 101.00 408.00 C 101.00 292.17 101.00 176.33 101.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 149.00 60.50 C 152.33 60.46 155.65 60.47 158.97 60.68 C 158.96 176.45 158.97 292.22 158.97 408.00 C 155.65 408.00 152.32 408.00 149.00 408.00 C 149.00 292.17 149.00 176.33 149.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 178.00 60.50 C 187.67 60.60 197.35 60.28 207.01 60.67 C 206.99 176.45 207.01 292.22 207.00 408.00 C 197.33 408.00 187.67 408.00 178.00 408.00 C 178.00 292.17 178.00 176.33 178.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 216.49 407.99 C 216.51 292.16 216.49 176.33 216.50 60.50 C 222.83 60.50 229.16 60.50 235.50 60.50 C 235.51 176.33 235.50 292.16 235.50 408.00 C 229.16 408.00 222.83 408.01 216.49 407.99 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 254.92 60.50 C 261.28 60.49 267.64 60.50 274.00 60.50 C 274.00 176.33 274.00 292.17 274.00 408.00 C 267.63 408.00 261.25 408.00 254.88 408.00 C 254.90 292.16 254.83 176.33 254.92 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 293.02 60.68 C 296.34 60.47 299.67 60.46 303.00 60.50 C 303.00 176.33 303.00 292.17 303.00 408.00 C 299.67 408.00 296.35 408.00 293.03 408.00 C 293.03 292.22 293.03 176.45 293.02 60.68 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 399.00 60.50 C 402.32 60.46 405.65 60.47 408.97 60.68 C 408.96 176.45 408.97 292.22 408.97 408.00 C 405.64 408.00 402.32 408.00 399.00 408.00 C 399.00 292.17 399.00 176.33 399.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 428.00 60.50 C 437.67 60.60 447.34 60.28 457.01 60.67 C 456.99 176.45 457.01 292.22 457.00 408.00 C 447.33 408.00 437.67 408.00 428.00 408.00 C 428.00 292.17 428.00 176.33 428.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 466.50 408.00 C 466.50 292.17 466.49 176.33 466.50 60.50 C 469.67 60.50 472.83 60.50 476.00 60.50 C 476.00 176.33 476.00 292.17 476.00 408.00 C 472.83 408.00 469.66 408.00 466.50 408.00 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 485.49 407.99 C 485.51 292.16 485.49 176.34 485.50 60.51 C 488.69 60.91 492.66 59.55 495.39 61.22 C 495.65 176.80 495.43 292.41 495.50 408.00 C 492.16 408.00 488.83 408.00 485.49 407.99 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 504.92 60.50 C 508.11 60.50 511.30 60.50 514.50 60.50 C 514.51 176.33 514.49 292.16 514.51 407.99 C 511.30 408.00 508.09 408.00 504.88 408.00 C 504.90 292.17 504.83 176.33 504.92 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 524.00 60.50 C 530.45 60.49 536.89 60.52 543.34 60.48 C 543.72 114.66 543.39 168.86 543.50 223.05 C 543.50 284.70 543.50 346.35 543.50 408.00 C 537.00 408.00 530.50 408.00 524.00 408.00 C 524.00 292.17 524.00 176.33 524.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 591.49 407.99 C 591.51 292.16 591.49 176.33 591.50 60.50 C 597.83 60.50 604.16 60.50 610.50 60.50 C 610.51 176.33 610.49 292.16 610.51 407.99 C 604.17 408.01 597.83 408.00 591.49 407.99 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 658.50 75.03 C 658.52 70.18 658.37 65.32 658.66 60.48 C 661.88 60.50 665.11 60.50 668.34 60.48 C 668.71 114.33 668.40 168.19 668.50 222.05 C 668.50 284.03 668.50 346.01 668.50 408.00 C 665.16 408.00 661.82 408.00 658.48 407.98 C 658.52 296.99 658.48 186.01 658.50 75.03 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 706.46 65.00 C 706.70 63.58 705.94 61.67 707.17 60.61 C 710.26 60.33 713.38 60.53 716.50 60.51 C 716.51 176.34 716.50 292.17 716.50 408.00 C 713.16 408.00 709.83 408.00 706.50 408.00 C 706.47 293.67 706.55 179.33 706.46 65.00 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 726.00 60.50 C 729.16 60.50 732.32 60.50 735.50 60.50 C 735.51 176.33 735.49 292.16 735.51 407.99 C 732.33 408.00 729.16 408.00 726.00 408.00 C 726.00 292.17 726.00 176.33 726.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 744.99 60.68 C 754.65 60.28 764.33 60.60 774.00 60.50 C 774.00 176.33 774.00 292.17 774.00 408.00 C 764.33 408.00 754.67 408.00 745.00 408.00 C 744.99 292.22 745.01 176.45 744.99 60.68 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 793.02 60.68 C 796.34 60.47 799.67 60.46 803.00 60.50 C 803.00 176.33 803.00 292.17 803.00 408.00 C 799.67 408.00 796.35 408.00 793.03 408.00 C 793.02 292.23 793.03 176.45 793.02 60.68 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 822.01 60.53 C 831.67 60.46 841.33 60.51 851.00 60.50 C 851.00 176.33 851.00 292.17 851.00 408.00 C 841.33 408.00 831.67 408.00 822.00 408.00 C 822.01 292.18 821.99 176.35 822.01 60.53 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 860.50 407.99 C 860.50 292.17 860.49 176.34 860.50 60.51 C 863.68 60.92 867.67 59.54 870.39 61.22 C 870.66 176.80 870.41 292.40 870.51 407.99 C 867.17 408.00 863.83 408.00 860.50 407.99 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 899.00 60.50 C 902.32 60.46 905.65 60.47 908.97 60.68 C 908.97 176.45 908.97 292.22 908.97 408.00 C 905.64 408.00 902.32 408.00 899.00 408.00 C 899.00 292.17 899.00 176.33 899.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 928.00 60.50 C 931.16 60.50 934.32 60.50 937.50 60.50 C 937.51 223.33 937.49 386.16 937.51 548.99 C 934.33 549.00 931.16 549.00 928.00 549.00 C 928.00 386.17 928.00 223.33 928.00 60.50 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 947.01 60.53 C 950.34 60.45 953.67 60.47 957.01 60.68 C 956.99 223.45 957.01 386.22 957.00 549.00 C 953.66 549.00 950.33 549.00 947.00 549.00 C 947.01 386.18 946.99 223.35 947.01 60.53 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 324.23 430.31 C 341.05 428.13 358.96 431.19 373.04 440.99 C 387.44 450.74 396.64 466.98 399.43 483.97 C 402.57 503.18 399.57 524.02 388.21 540.17 C 378.96 553.66 363.86 562.59 347.83 565.31 C 332.91 567.73 317.02 566.37 303.38 559.53 C 292.85 554.39 283.94 546.07 278.07 535.93 C 269.42 521.30 267.00 503.62 269.13 486.93 C 271.09 471.18 278.36 455.86 290.38 445.32 C 299.80 437.03 311.81 431.88 324.23 430.31 M 324.48 450.73 C 312.11 452.99 301.11 461.52 296.14 473.12 C 290.14 486.82 289.83 502.73 293.84 517.03 C 297.30 529.36 306.70 540.05 319.06 543.94 C 331.57 547.85 345.96 547.16 357.45 540.51 C 366.49 535.30 372.58 526.03 375.35 516.11 C 378.19 505.85 378.32 494.92 376.41 484.48 C 374.35 474.11 369.20 463.94 360.48 457.65 C 350.26 450.14 336.72 448.38 324.48 450.73 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 783.14 430.15 C 799.17 428.34 816.08 431.30 829.64 440.35 C 844.54 450.02 854.15 466.59 856.97 483.96 C 860.07 502.84 857.22 523.29 846.33 539.34 C 837.41 552.78 822.77 561.98 806.99 565.00 C 791.03 568.00 773.82 566.46 759.36 558.73 C 748.50 553.04 739.56 543.88 733.98 532.97 C 725.91 517.46 724.32 499.15 727.46 482.12 C 730.42 466.66 738.68 451.97 751.41 442.50 C 760.59 435.57 771.72 431.36 783.14 430.15 M 781.48 450.83 C 775.63 452.10 770.00 454.56 765.26 458.24 C 756.02 465.29 751.00 476.60 749.47 487.89 C 747.74 501.18 748.96 515.45 755.84 527.21 C 761.25 536.59 770.90 543.17 781.48 545.26 C 792.14 547.30 803.69 546.51 813.42 541.40 C 820.67 537.65 826.43 531.32 829.90 523.97 C 835.45 512.38 836.19 499.01 834.33 486.47 C 832.52 475.21 827.12 464.04 817.62 457.35 C 807.28 449.97 793.72 448.37 781.48 450.83 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 154.51 433.54 C 155.32 432.73 156.14 431.92 156.96 431.12 C 171.97 431.16 186.98 431.05 201.98 431.17 C 213.73 431.60 226.26 433.82 235.50 441.63 C 244.77 449.44 247.57 462.76 245.28 474.27 C 243.29 483.81 236.09 491.45 227.55 495.74 C 234.76 498.26 241.81 502.37 245.93 509.01 C 255.10 524.54 249.85 546.87 234.62 556.63 C 225.36 562.86 214.01 565.03 203.01 565.08 C 187.66 565.18 172.30 565.09 156.95 565.13 C 156.12 564.30 155.30 563.49 154.49 562.67 C 154.51 519.63 154.48 476.58 154.51 433.54 M 176.88 449.88 C 176.87 462.88 176.87 475.87 176.88 488.87 C 184.27 488.85 191.67 488.92 199.06 488.86 C 205.50 488.72 212.26 487.45 217.47 483.44 C 221.60 480.31 223.61 475.08 223.68 470.01 C 223.86 464.55 222.55 458.47 218.09 454.89 C 213.00 450.92 206.26 450.13 200.01 449.90 C 192.30 449.83 184.59 449.89 176.88 449.88 M 176.88 506.25 C 176.87 519.58 176.87 532.91 176.88 546.25 C 186.24 546.19 195.61 546.38 204.97 546.16 C 212.64 545.87 221.34 543.48 225.50 536.45 C 229.14 529.72 229.43 520.58 224.71 514.30 C 220.16 508.36 212.25 506.70 205.18 506.36 C 195.75 506.11 186.31 506.32 176.88 506.25 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 424.22 433.40 C 425.37 432.58 426.29 431.03 427.88 431.15 C 432.95 431.07 438.02 431.06 443.08 431.16 C 444.70 431.01 445.61 432.55 446.77 433.40 C 446.72 470.72 446.76 508.05 446.75 545.37 C 463.85 545.38 480.94 545.38 498.04 545.37 C 499.99 545.01 501.26 546.55 502.55 547.72 C 502.45 552.72 502.52 557.72 502.49 562.73 C 501.67 563.52 500.85 564.32 500.04 565.13 C 475.64 565.13 451.25 565.11 426.86 565.13 C 425.98 564.29 425.11 563.44 424.25 562.59 C 424.23 519.53 424.29 476.46 424.22 433.40 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 521.61 433.60 C 522.42 432.76 523.24 431.93 524.08 431.12 C 550.65 431.10 577.23 431.18 603.81 431.08 C 604.66 432.27 606.26 433.24 606.10 434.92 C 606.24 439.25 606.06 443.58 606.13 447.92 C 605.32 448.73 604.51 449.55 603.72 450.37 C 583.90 450.39 564.07 450.37 544.26 450.38 C 544.25 462.96 544.25 475.54 544.25 488.12 C 560.10 488.12 575.95 488.12 591.80 488.12 C 592.66 488.97 593.52 489.84 594.38 490.72 C 594.37 495.24 594.37 499.76 594.38 504.29 C 593.52 505.15 592.65 506.01 591.79 506.88 C 575.95 506.87 560.10 506.87 544.25 506.88 C 544.25 519.88 544.25 532.87 544.25 545.87 C 563.56 545.90 582.86 545.83 602.17 545.90 C 603.87 545.68 604.91 547.24 606.15 548.12 C 606.04 552.46 606.25 556.81 606.10 561.16 C 606.30 563.24 604.27 565.29 602.19 565.10 C 576.13 565.17 550.06 565.10 524.00 565.13 C 523.11 563.88 521.44 562.87 521.65 561.14 C 521.59 518.63 521.65 476.11 521.61 433.60 Z"
      />
      <path
        fill="#FFFF"
        opacity="1.00"
        d=" M 620.44 431.13 C 654.15 431.12 687.86 431.11 721.57 431.14 C 722.35 431.89 723.14 432.65 723.95 433.41 C 724.04 438.36 723.98 443.30 724.02 448.25 C 723.21 449.08 722.39 449.91 721.59 450.75 C 708.48 450.75 695.36 450.74 682.25 450.75 C 682.23 487.57 682.29 524.40 682.23 561.22 C 682.47 562.96 680.82 563.95 679.92 565.17 C 674.08 565.11 668.25 565.05 662.42 565.22 C 661.42 564.02 659.63 563.04 659.89 561.24 C 659.85 524.41 659.89 487.58 659.87 450.75 C 646.68 450.77 633.49 450.70 620.30 450.79 C 619.46 449.62 617.88 448.71 618.03 447.09 C 617.88 442.53 618.02 437.97 618.05 433.41 C 618.84 432.65 619.64 431.89 620.44 431.13 Z"
      />
    </g>
  </svg>
)

export default NewBoletoIcon
