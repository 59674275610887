import React from 'react'
import type { FC } from 'react'

const EmailIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="16"
    viewBox="0 0 22 16"
  >
    <defs>
      <clipPath id="86owa">
        <path d="M13.722 8.622l7.777 6.67H.497l7.781-6.67L11 11.004zM11 9.432L.5.434 21.503.43zM7.595 7.986L0 14.44V1.384zM22 1.38v13.06l-7.595-6.454z" />
      </clipPath>
    </defs>
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#030104"
              d="M13.722 8.622l7.777 6.67H.497l7.781-6.67L11 11.004zM11 9.432L.5.434 21.503.43zM7.595 7.986L0 14.44V1.384zM22 1.38v13.06l-7.595-6.454z"
            />
          </g>
          <g clipPath="url(#86owa)">
            <g>
              <path d="M0-3h22v22H0z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default EmailIcon
