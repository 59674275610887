import { Link } from '@vtex/store-ui'
import React from 'react'

interface Props {
  color?: string
}

const FacebookFilledIcon = ({ color }: Props) => (
  <Link
    href="https://www.facebook.com/avonbr"
    target="_blank"
    aria-label="Facebook Icon"
    title="Facebook"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g>
        <g>
          <path
            fill={color ?? '#242020'}
            d="M18.896 0H1.104C.494 0 0 .494 0 1.104v17.792C0 19.506.494 20 1.104 20h9.579v-7.745H8.076V9.236h2.607V7.011c0-2.584 1.577-3.99 3.882-3.99 1.104 0 2.053.082 2.33.119v2.7h-1.6c-1.252 0-1.495.596-1.495 1.47v1.926h2.989l-.39 3.019H13.8V20h5.096c.61 0 1.104-.494 1.104-1.104V1.104C20 .494 19.506 0 18.896 0"
          />
        </g>
      </g>
    </svg>
  </Link>
)

export default FacebookFilledIcon
