import { styled } from 'src/gatsby-theme-stitches/config'

export const HeaderContainer = styled('header', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2.25rem',
  fontFamily: '$roboto',
  position: 'sticky',
  top: '0',
  zIndex: '10',
  backgroundColor: '$white',
  paddingTop: '1.125rem',
  paddingBottom: '.75rem',

  '@sm': {
    display: 'none',
  },

  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '85%',
    maxWidth: '80rem',
    mx: 'auto',
  },

  'div[aria-label="logo"]': {
    svg: {
      width: '7.5rem',
    },
  },
})

export const RepresentativeBarContainer = styled('div', {
  width: '100%',
  padding: '.25rem 0',
  background: '#FAD3E4',

  p: {
    fontFamily: '$roboto',
    fontSize: '1.25rem',
    textAlign: 'center',
    color: '#000',

    '@sm': {
      fontSize: '1rem',
    },

    strong: {
      fontWeight: '900',
    },
  },
})
