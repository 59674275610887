import React, { Suspense, useEffect, useState } from 'react'
import { Center, SearchBar as StoreUISearchBar, Spinner } from '@vtex/store-ui'
import SearchSuggestionsContainer from 'src/components/common/SearchSuggestions/Container'
import loadable from '@loadable/component'
import { useFullTextSearch } from '@vtex/gatsby-theme-store'
import { Icon } from '@naturacosmeticos/natds-react'
import { useColabStore } from 'src/hooks/useColabStore'

import { SearchBarContainer } from './styled'

const SearchSuggestions = loadable(
  () => import('src/components/common/Suggestions')
)

const SearchBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const onSearch = useFullTextSearch()

  const handleScroll = () => {
    const position = window.scrollY

    return setScrollPosition(position)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll, { passive: true })

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }

    return () => null
  }, [])

  const { isUserColab } = useColabStore()

  return (
    <SearchBarContainer
      css={{
        $$top: scrollPosition >= 90 ? '4rem' : isUserColab ? '7rem' : '10rem',

        '@sm': {
          $$top: scrollPosition >= 40 ? '45px' : isUserColab ? '45px' : '98px',
        },
      }}
    >
      <StoreUISearchBar
        onSearch={onSearch}
        placeholder="O que você procura hoje?"
        aria-label="O que você procura hoje?"
        icon={<Icon name="outlined-action-search" />}
      >
        <SearchSuggestionsContainer>
          <Suspense
            fallback={
              <Center>
                <Spinner />
              </Center>
            }
          >
            <SearchSuggestions />
          </Suspense>
        </SearchSuggestionsContainer>
      </StoreUISearchBar>
    </SearchBarContainer>
  )
}

export default SearchBar
