import React from 'react'
import loadable from '@loadable/component'
import useStoreType from 'src/contexts/StoreTypeContext/useStoreType'

import RepresentativeMenu from './RepresentativeMenu'
import { NavigationBarContainer, NavigationBarWrapper } from './styled'

const ColabLogout = loadable(() => import('./ColabLogout'))

const NavigationBar = () => {
  const { isCollaboratorStore } = useStoreType()

  return (
    <NavigationBarWrapper>
      <NavigationBarContainer>
        {isCollaboratorStore ? <ColabLogout /> : <RepresentativeMenu />}
      </NavigationBarContainer>
    </NavigationBarWrapper>
  )
}

export default NavigationBar
