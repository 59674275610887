import { useContext } from 'react'
import { StoreTypeContext } from 'src/contexts/StoreTypeContext'

const useStoreType = () => {
  const context = useContext(StoreTypeContext)

  if (context === undefined) {
    throw new Error('Region context provider missing from React tree')
  }

  return context
}

export default useStoreType
