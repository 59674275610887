import React from 'react'
import type { FC } from 'react'

const BlackMagazineIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.583"
    height="12.957"
    viewBox="0 0 14.583 12.957"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_3818"
          data-name="Rectangle 3818"
          width="14.583"
          height="12.957"
          fill="#f18fd0"
        />
      </clipPath>
    </defs>
    <g id="Group_8753" data-name="Group 8753" transform="translate(0 0)">
      <g
        id="Group_8734"
        data-name="Group 8734"
        transform="translate(0 0)"
        clipPath="url(#clip-path)"
      >
        <path
          id="Path_6698"
          data-name="Path 6698"
          d="M.891,11.476V2.522c0-.411.019-.827,0-1.237,0-.006,0-.012,0-.018l-.13.314c.019-.017.063-.05-.017.012s.016-.01.042-.027a2.754,2.754,0,0,1,.344-.192l.114-.052c.085-.039-.106.043-.016.007L1.305,1.3q.169-.066.343-.118a6.46,6.46,0,0,1,.892-.2L2.662.961c-.114.017.041,0,.063-.007q.154-.018.308-.03Q3.351.9,3.67.893a9.9,9.9,0,0,1,1.31.061c.019,0,.145.019.086.01s0,0,.012,0l.069.01q.132.02.263.045.226.043.449.1.183.049.363.111.079.027.156.058l.067.027c.083.033-.1-.046-.008,0a3.128,3.128,0,0,1,.427.228c.058.038.159.145.02.009.01.01.022.018.033.027l-.13-.314v8.954c0,.411-.019.826,0,1.237,0,.006,0,.012,0,.018l.562-.428a23.07,23.07,0,0,0-2.365-.431,9.224,9.224,0,0,0-1.722-.092,12.823,12.823,0,0,0-1.916.274c-.341.072-.682.151-1.017.249a.444.444,0,0,0,.236.856c.268-.078.541-.143.814-.2s.556-.118.837-.165L2.4,11.5l.115-.017c.014,0,.143-.02.037-.006.17-.022.34-.041.51-.055a8.606,8.606,0,0,1,1.7.052l.178.022c.121.015-.1-.014.024,0l.106.015q.225.032.449.07.374.062.745.136c.281.055.562.113.841.18a.449.449,0,0,0,.562-.428V2.951c0-.559.022-1.123,0-1.681-.015-.365-.451-.565-.74-.7A6.688,6.688,0,0,0,4.654.034a9.227,9.227,0,0,0-3.01.223A4.155,4.155,0,0,0,.3.822a.531.531,0,0,0-.3.45c0,.052,0,.1,0,.155V11.476a.449.449,0,0,0,.444.444.452.452,0,0,0,.444-.444"
          transform="translate(0 0)"
          fill="#000"
        />
        <path
          id="Path_6699"
          data-name="Path 6699"
          d="M276.977,35.571v.419H283a.033.033,0,0,1,.014,0,.037.037,0,0,1,.016.013.033.033,0,0,1,0,.009.035.035,0,0,1,0,.011v9.641a.035.035,0,0,1,0,.014.038.038,0,0,1-.013.016l-.009,0-.011,0h-6.023a.034.034,0,0,1-.014,0,.037.037,0,0,1-.016-.013.034.034,0,0,1,0-.009.037.037,0,0,1,0-.011V36.026a.036.036,0,0,1,0-.014.037.037,0,0,1,.013-.016l.009,0,.011,0v-.839a.874.874,0,0,0-.874.874v9.641a.874.874,0,0,0,.874.874H283a.874.874,0,0,0,.875-.874V36.026a.874.874,0,0,0-.875-.874h-6.023Z"
          transform="translate(-269.291 -34.285)"
          fill="#000"
        />
        <path
          id="Path_6700"
          data-name="Path 6700"
          d="M325.819,91.453v.419h3.922v-.129l-.051.118a.131.131,0,0,0,.051.011v-.129l-.051.118.048-.111-.1.067a.12.12,0,0,0,.053.044l.048-.111-.1.067.093-.061h-.112a.113.113,0,0,0,.019.061l.093-.061h-.112v6.428h.11l-.1-.042a.111.111,0,0,0-.008.042h.11l-.1-.042.108.045-.066-.1a.117.117,0,0,0-.042.051l.108.045-.066-.1.072.1-.039-.121a.126.126,0,0,0-.034.016l.072.1-.039-.121.039.123v-.129a.13.13,0,0,0-.039.006l.039.123v-.129h-3.922V98.2l.051-.118a.13.13,0,0,0-.051-.011V98.2l.051-.118-.048.111.1-.067a.12.12,0,0,0-.053-.044l-.048.111.1-.067-.093.061h.112a.112.112,0,0,0-.019-.061l-.093.061h.112V91.757h-.11l.1.042a.11.11,0,0,0,.009-.042h-.11l.1.042-.108-.045.066.1a.116.116,0,0,0,.042-.051l-.108-.045.066.1-.072-.1.039.121a.126.126,0,0,0,.034-.017l-.072-.1.039.121-.039-.123v.129a.13.13,0,0,0,.039-.006l-.039-.123v-.71a.709.709,0,0,0-.282.058.718.718,0,0,0-.314.264.726.726,0,0,0-.12.4v6.428a.725.725,0,0,0,.311.6.709.709,0,0,0,.4.126h3.922a.71.71,0,0,0,.282-.058.718.718,0,0,0,.314-.264.726.726,0,0,0,.12-.4V91.757a.725.725,0,0,0-.311-.6.709.709,0,0,0-.4-.126h-3.922Z"
          transform="translate(-317.082 -88.788)"
          fill="#000"
        />
        <path
          id="Path_6701"
          data-name="Path 6701"
          d="M417.917,417.559a.407.407,0,1,1-.407-.407.407.407,0,0,1,.407.407"
          transform="translate(-406.812 -406.86)"
          fill="#000"
        />
        <path
          id="Path_6702"
          data-name="Path 6702"
          d="M24.591,475.091a2.379,2.379,0,0,1,.251-.137q.068-.033.137-.064l.072-.031c-.093.041.018-.007.04-.016q.206-.082.418-.146.257-.078.521-.132.134-.027.269-.049l.084-.013a.145.145,0,0,1,.036,0l.178-.02a6.393,6.393,0,0,1,1.463.029,7.568,7.568,0,0,1,2.006.582.305.305,0,0,0,.405-.106.3.3,0,0,0-.106-.405,6.992,6.992,0,0,0-5.737-.177,2.646,2.646,0,0,0-.335.177.3.3,0,0,0-.106.405.3.3,0,0,0,.405.106"
          transform="translate(-23.551 -462.175)"
          fill="#000"
        />
      </g>
    </g>
  </svg>
)

export default BlackMagazineIcon
