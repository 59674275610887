export const EventMenu = (
  goToMenu?: string | null,
  nameCategoryUpper?: string | null
) => {
  window.dataLayer = window.dataLayer || []

  const { dataLayer } = window

  dataLayer.push({
    event: 'sendEvent',
    category: 'menu',
    action: goToMenu,
    label: nameCategoryUpper,
  })

  return null
}
