import React from 'react'
import { Link } from '@vtex/store-ui'

interface Props {
  color?: string
}

const FacebookIcon = ({ color }: Props) => (
  <Link
    href="https://www.facebook.com/avonbr"
    target="_blank"
    aria-label="Facebook Icon"
    title="Facebook Icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.613"
      height="18.513"
      viewBox="0 0 9.613 18.513"
    >
      <path
        id="f_1_"
        d="M43.53,18.513V10.069h2.833l.425-3.292H43.53v-2.1c0-.953.263-1.6,1.631-1.6H46.9V.129A23.616,23.616,0,0,0,44.365,0a3.965,3.965,0,0,0-4.233,4.35V6.777H37.29v3.292h2.842v8.444Z"
        transform="translate(-37.29)"
        fill={color ?? '#fff'}
      />
    </svg>
  </Link>
)

export default FacebookIcon
