import { useContext } from 'react'
import { RepresentativeContext } from 'src/contexts/RepresentativeContext'

const useRepresentative = () => {
  const context = useContext(RepresentativeContext)

  if (context === undefined) {
    throw new Error('Region context provider missing from React tree')
  }

  return context
}

export default useRepresentative
