import React from 'react'
import type { FC } from 'react'

const ChangeIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="20"
    viewBox="0 0 18.34 21.498"
  >
    <g id="noun-refresh-4894952" transform="translate(-2.83 -1.252)">
      <path
        id="Path_6742"
        data-name="Path 6742"
        d="M20.42,12.22a.755.755,0,0,1-.75-.75V8.15A2.253,2.253,0,0,0,17.42,5.9H3.58a.75.75,0,0,1,0-1.5H17.42a3.751,3.751,0,0,1,3.75,3.75v3.32A.749.749,0,0,1,20.42,12.22Z"
      />
      <path
        id="Path_6743"
        data-name="Path 6743"
        d="M6.74,9.07a.742.742,0,0,1-.53-.22L3.05,5.69a.748.748,0,0,1,0-1.06L6.21,1.47A.75.75,0,0,1,7.27,2.53L4.64,5.16,7.27,7.79a.754.754,0,0,1,0,1.06A.786.786,0,0,1,6.74,9.07Z"
      />
      <path
        id="Path_6744"
        data-name="Path 6744"
        d="M20.42,19.59H6.58a3.751,3.751,0,0,1-3.75-3.75V12.52a.75.75,0,0,1,1.5,0v3.32a2.253,2.253,0,0,0,2.25,2.25H20.42a.75.75,0,0,1,0,1.5Z"
      />
      <path
        id="Path_6745"
        data-name="Path 6745"
        d="M17.26,22.75a.742.742,0,0,1-.53-.22.755.755,0,0,1,0-1.06l2.63-2.63-2.63-2.63a.75.75,0,0,1,1.06-1.06l3.16,3.16a.748.748,0,0,1,0,1.06l-3.16,3.16A.71.71,0,0,1,17.26,22.75Z"
      />
    </g>
  </svg>
)

export default ChangeIcon
