import React from 'react'
import type { FC } from 'react'

const ShareIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
  >
    <g>
      <g>
        <g>
          <path
            fill="none"
            stroke="#242020"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="50"
            strokeWidth="2"
            d="M6.333 9.054a2.675 2.675 0 0 1-2.666 2.684A2.675 2.675 0 0 1 1 9.054 2.675 2.675 0 0 1 3.667 6.37a2.675 2.675 0 0 1 2.666 2.684z"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#242020"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="50"
            strokeWidth="2"
            d="M17.003 15.314a2.675 2.675 0 0 1-2.666 2.684 2.675 2.675 0 0 1-2.667-2.684 2.675 2.675 0 0 1 2.667-2.684 2.675 2.675 0 0 1 2.666 2.684z"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#242020"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="50"
            strokeWidth="2"
            d="M17.003 3.684a2.675 2.675 0 0 1-2.666 2.684 2.675 2.675 0 0 1-2.667-2.684A2.675 2.675 0 0 1 14.337 1a2.675 2.675 0 0 1 2.666 2.684z"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#242020"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="50"
            strokeWidth="2"
            d="M8.47 11.875l1.067.625"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#242020"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="50"
            strokeWidth="2"
            d="M8.47 6.636l1.067-.537"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default ShareIcon
