import React from 'react'
import { Logo } from '@naturacosmeticos/natds-react'

import {
  CopyrightContainer,
  FooterContainer,
  UpSideContainer,
  CopyrightTopText,
  CopyrightBottomText,
  LogoContainer,
  FooterSectionsWrapper,
} from './styled'
import SocialMedia from './SocialMedia'
import PaymentMethods from './PaymentMethods'
import Links from './Links'

const Footer = () => {
  return (
    <FooterContainer>
      <FooterSectionsWrapper>
        <UpSideContainer>
          <LogoContainer>
            <Logo size="largeXXX" color="surface" />

            <p>
              Somos a companhia que empodera mulheres com inspiração e
              independência financeira.
            </p>
          </LogoContainer>

          <SocialMedia />
          <PaymentMethods />
        </UpSideContainer>

        <Links />

        <CopyrightContainer>
          <CopyrightTopText>
            <p>© 2023 Avon ©Copyright 2023 AVON COSMÉTICOS.</p>
          </CopyrightTopText>
          <CopyrightBottomText>
            <p>
              AVON COSMÉTICOS LTDA. - CNPJ/MF 56.991.441/0001-57 - Sede: Avenida
              Interlagos, 4.300, São Paulo - SP - CEP 04660-907 - Ligações de
              celulares e fixos (ligação gratuita): 0800 708 2866 Todos os
              preços e condições deste site são válidos apenas para compras no
              site. Destacamos que os preços previstos no site prevalecem aos
              demais anunciados em outros meios de comunicação e sites de
              buscas. Em caso de divergência, o preço válido é o do carrinho de
              compras. Imagens meramente ilustrativas.Procon
            </p>
          </CopyrightBottomText>
        </CopyrightContainer>
      </FooterSectionsWrapper>
    </FooterContainer>
  )
}

export default Footer
