import React from 'react'
import { FrameIcon, InfotechnologyIcon, VtexIcon } from 'src/components/icons'
import GoogleFooterIcon from 'src/components/icons/GoogleCertificate'
import EnextIcon from 'src/components/icons/EnextIcon'
import { SuspenseViewport } from '@vtex/store-ui'

import {
  CertificatesContainer,
  EnextLogoContainer,
  IsoCertificates,
  NavContainer,
  NavTexts,
  Paragraph,
  RealizationsContainer,
  UpSideCertificates,
  VtexLogoContainer,
} from './styled'

const Links = () => {
  return (
    <NavContainer>
      <div>
        <Paragraph navTitle>SOBRE A AVON</Paragraph>
        <NavTexts>
          <a href="/institucional/a-avon"> Sobre nós</a>
          <a href="/institucional/carreira">Carreiras</a>
          <a href="/institucional/etica-e-compliance">Ètica & Compliance</a>
          <a href="/institucional/politica-de-privacidade">
            Política de privacidade
          </a>
          <a href="/institucional/imprensa">Imprensa</a>
          <a href="/cupom-de-desconto">Cupons de desconto</a>
        </NavTexts>
      </div>

      <div>
        <Paragraph navTitle>CONSULTORA AVON</Paragraph>
        <NavTexts>
          <a href="https://www.avoncomigo.avon.com.br/widget/avonwg2/#/login">
            Já sou Consultora
          </a>
          <a href="https://accounts.natura-avon.com/queroserconsultora?utm_source=avon&utm_medium=site&utm_term=botao_topo&utm_content=perene&utm_campaign=avon_site_institucional_botao_topo">
            Quero ser Consultora
          </a>
          {/* <a href="/encontre-representante-avon">
            Encontre um(a) Representante
          </a> */}
          <a
            href="https://conecta.avon.com.br/revista/?utm_source=
                footer-lojab2c&utm_medium=footer&utm_campaign=footer"
          >
            Revista Digital
          </a>
          <a href="/memoria">Memória Avon</a>
        </NavTexts>
      </div>

      <div>
        <Paragraph navTitle>INSTITUTO AVON</Paragraph>
        <NavTexts>
          <a href="/institucional/instituto-avon">Sobre</a>
          <a href="/instituto-avon/cancer-de-mama">Câncer de Mama</a>
          <a href="/instituto-avon/violencia-contra-mulheres">
            Violência Contra Meninas e Mulheres
          </a>
        </NavTexts>
      </div>

      <div>
        <Paragraph navTitle>SUPORTE</Paragraph>
        <NavTexts>
          <a href="/institucional/atendimento-ao-consumidor">
            Atendimento ao Cliente
          </a>
          <a href="/institucional/perguntas-e-respostas">F.A.Q</a>
          <a href="/institucional/regulamentos">Regulamento</a>
          <a href="/institucional/troca-e-devolucao">Troca e Devolução</a>
        </NavTexts>
      </div>

      <SuspenseViewport fallback={null}>
        <CertificatesContainer>
          <UpSideCertificates>
            <Paragraph>Certificados</Paragraph>

            <IsoCertificates>
              <a
                href="https://avongroup.vteximg.com.br/arquivos/ISO-14001-210916-2016-AE-BRA-RvA-3-en-US%20-%2020200114.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://avonqa.vteximg.com.br/arquivos/selo-iso14001.png"
                  alt="Ícone ISO 14001"
                  width="100px"
                  height="60px"
                  loading="lazy"
                />
              </a>

              <a
                href="https://avongroup.vteximg.com.br/arquivos/ISO-9001-210914-2016-AQ-BRA-RvA-3-en-US%20-%2020200114.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://avonqa.vteximg.com.br/arquivos/selo-iso9001.png"
                  alt="Ícone ISO 9001"
                  width="60px"
                  height="60px"
                  loading="lazy"
                />
              </a>

              <FrameIcon />
            </IsoCertificates>

            <GoogleFooterIcon />
          </UpSideCertificates>
          <RealizationsContainer>
            <VtexLogoContainer>
              <Paragraph>Plataforma</Paragraph>
              <VtexIcon />
            </VtexLogoContainer>
            <div>
              <Paragraph>Realização</Paragraph>
              <EnextLogoContainer>
                <EnextIcon />
                <InfotechnologyIcon />
              </EnextLogoContainer>
            </div>
          </RealizationsContainer>
        </CertificatesContainer>
      </SuspenseViewport>
    </NavContainer>
  )
}

export default Links
