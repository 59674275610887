import React from 'react'
import { navigate } from 'gatsby'
import { useGlobalUIState } from '@vtex/store-sdk'
import { Icon } from '@naturacosmeticos/natds-react'
import { useOrderForm } from '@vtex/gatsby-theme-store'
import useDeviceDetect from 'src/hooks/useDeviceDetect'
import Minicart from 'src/components/common/Minicart'

import { MyBagContainer } from './styled'

export const MyBagClient = () => {
  const { orderForm } = useOrderForm()
  const productsInBag = orderForm.items.filter((item) => !item.isGift)
  const totalItemsInBag = productsInBag.reduce(
    (acc, item) => item.quantity + acc,
    0
  )

  const itemsInBag = totalItemsInBag > 9 ? '9 +' : totalItemsInBag

  const { isMobile } = useDeviceDetect()
  const { openMinicart } = useGlobalUIState()

  const handleBagClick = () => {
    if (isMobile) {
      return navigate('/checkout')
    }

    return openMinicart()
  }

  return (
    <>
      <MyBagContainer onClick={handleBagClick}>
        <Icon name="outlined-finance-bag" size="semi" color="mediumEmphasis" />
        <span>{itemsInBag}</span>
      </MyBagContainer>

      {!isMobile && <Minicart />}
    </>
  )
}
