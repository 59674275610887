import React from 'react'
import type { FC } from 'react'

const MouthIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="70px"
    height="44px"
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
  >
    <g>
      <path
        style={{ opacity: '0.982' }}
        fill="#2f2623"
        d="M 21.5,11.5 C 26.2407,10.9626 30.574,11.9626 34.5,14.5C 41.7718,10.2811 48.7718,10.7811 55.5,16C 58.6828,19.0155 61.6828,22.1822 64.5,25.5C 44.4476,41.4908 24.4476,41.4908 4.5,25.5C 9.04544,19.2709 14.7121,14.6042 21.5,11.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: '1' }}
        fill="#ead2d9"
        d="M 9.5,26.5 C 25.9949,25.3384 42.6616,25.1717 59.5,26C 42.9204,37.1616 26.2538,37.3283 9.5,26.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: '1' }}
        fill="#e3ccd2"
        d="M 22.5,13.5 C 26.5644,13.7924 30.3977,14.7924 34,16.5C 37.7897,15.1713 41.623,14.338 45.5,14C 50.8129,15.9901 55.4796,18.9901 59.5,23C 42.8333,23.6667 26.1667,23.6667 9.5,23C 13.5461,19.3231 17.8794,16.1564 22.5,13.5 Z"
      />
    </g>
  </svg>
)

export default MouthIcon
