import React from 'react'

const TouchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.046"
    height="10.992"
    viewBox="0 0 25.046 10.992"
  >
    <g
      id="Group_10044"
      data-name="Group 10044"
      transform="translate(-1005.347 -494)"
    >
      <path
        id="noun-next-arrow-102118"
        d="M30.4,11.8c0-.191-.226-.378-.418-.5l-5.393-4.98-.958.942,4.21,3.887H17.875v1.333H27.84l-4.21,3.887.958.942L30.011,12.3C30.195,12.17,30.4,11.986,30.4,11.8Z"
        transform="translate(999.995 487.683)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="noun-next-arrow-102118-2"
        data-name="noun-next-arrow-102118"
        d="M12.523,5.484c0-.191-.226-.378-.418-.5L6.713,0,5.755.942l4.21,3.887H0V6.162H9.965l-4.21,3.887.958.942,5.423-5.008C12.32,5.853,12.523,5.669,12.523,5.484Z"
        transform="translate(1017.87 504.991) rotate(180)"
        fill="#fff"
        fillRule="evenodd"
      />
    </g>
  </svg>
)

export default TouchIcon
