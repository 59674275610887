import React from 'react'
import { Icon } from '@naturacosmeticos/natds-react'

import { UserButton } from './styled'

interface Props {
  onClick?: () => void
}

export const UserFallback = ({ onClick }: Props) => {
  return (
    <UserButton onClick={onClick}>
      <Icon
        name="outlined-social-myprofile"
        size="semi"
        color="mediumEmphasis"
      />

      <div>
        <p>Olá, Cliente</p>
        <span>Meus dados</span>
      </div>

      <Icon
        name="outlined-navigation-arrowbottom"
        size="small"
        color="mediumEmphasis"
      />
    </UserButton>
  )
}
