import React from 'react'
import type { FallbackProps } from 'react-error-boundary'
import { navigate } from 'gatsby'
import { Center, Flex } from '@vtex/store-ui'
import Layout from 'src/components/Layout'

const ErrorFallback = ({ error }: FallbackProps) => {
  const handleClick = () => {
    navigate('/')
  }

  return (
    <Layout>
      <Flex>
        <Center sx={{ textAlign: 'center' }}>
          <div role="alert">
            <p>Algo deu errado:</p>
            <pre style={{ color: 'red' }}>{error.message}</pre>
            <button onClick={handleClick}>Tente novamente</button>
          </div>
        </Center>
      </Flex>
    </Layout>
  )
}

export default ErrorFallback
