import React from 'react'
import type { FC } from 'react'

const DinersIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 0 110.27 60.82">
    <title>diners</title>
    <g id="Camada_2" data-name="Camada 2" height="100">
      <g id="Layer_1" data-name="Layer 1" height="30">
        <path
          height="50"
          fill="#FFF"
          d="M72.07,62.17A31,31,0,0,1,41,31.36C41,12.82,55,0,72.07,0h8c16.85,0,32.22,12.81,32.22,31.36,0,17-15.37,30.81-32.22,30.81Zm.07-59.6A28.46,28.46,0,1,0,100.35,31,28.35,28.35,0,0,0,72.14,2.57ZM65.73,47.86V14.2a18.08,18.08,0,0,0,0,33.66ZM90,31A18,18,0,0,0,78.54,14.2V47.87A18,18,0,0,0,90,31Z"
        />
      </g>
    </g>
  </svg>
)

export default DinersIcon
