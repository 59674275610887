import React from 'react'

const ArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="524px"
    height="524px"
    viewBox="0 -6 524 524"
  >
    <title>down</title>
    <path d="M64 191L98 157 262 320 426 157 460 191 262 387 64 191Z" />
  </svg>
)

export default ArrowDown
