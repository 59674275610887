import React from 'react'
import type { FC } from 'react'

const VisaIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 216 69.76"
  >
    <title>visa</title>
    <g id="Camada_2" data-name="Camada 2">
      <g id="Visa_Logo" data-name="Visa Logo">
        <path
          fill="#FFF"
          d="M82,1.23,53.73,68.7H35.28L21.36,14.86c-.84-3.32-1.57-4.53-4.14-5.93C13,6.65,6.09,4.52,0,3.19l.41-2h29.7a8.14,8.14,0,0,1,8,6.88l7.35,39.05L63.68,1.23ZM154.3,46.67c.08-17.8-24.62-18.78-24.45-26.74.05-2.42,2.36-5,7.4-5.65a32.88,32.88,0,0,1,17.21,3L157.53,3A47.09,47.09,0,0,0,141.2,0C124,0,111.8,9.17,111.7,22.3,111.59,32,120.37,37.43,127,40.67c6.79,3.3,9.07,5.42,9,8.38,0,4.53-5.43,6.52-10.45,6.6-8.77.14-13.87-2.37-17.93-4.26L104.5,66.17c4.08,1.88,11.61,3.51,19.41,3.59,18.34,0,30.33-9.06,30.39-23.09m45.56,22H216L201.91,1.23H187a8,8,0,0,0-7.43,4.95L153.39,68.7h18.33l3.64-10.08h22.39ZM180.38,44.8l9.19-25.34,5.29,25.34ZM107,1.23,92.52,68.7H75.06L89.5,1.23Z"
        />
      </g>
    </g>
  </svg>
)

export default VisaIcon
