import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Icon } from '@naturacosmeticos/natds-react'
import { useColabStore } from 'src/hooks/useColabStore'
import { EventMenu } from 'src/components/event/EventMenu'

import {
  ExtraLinksContainer,
  ItemWrapper,
  SeeAllContainer,
  SubmenuContainer,
  SubmenuItem,
} from './styled'
import type { MenuItem as MenuType } from './menu'

interface Props {
  menu: MenuType
}

const MenuItem = ({ menu }: Props) => {
  const [showSubmenu, setShowSubmenu] = useState(false)

  const { isUserColab } = useColabStore()

  const isOutletCollaborator = isUserColab && menu.name === 'Outlet'

  return (
    <ItemWrapper
      key={menu.link}
      css={{ $$color: menu.color ?? '#777' }}
      onMouseEnter={() => setShowSubmenu(true)}
      onMouseLeave={() => setShowSubmenu(false)}
      showSubmenu={showSubmenu}
    >
      <Link
        to={isOutletCollaborator ? '/outletcolaborador' : menu.link}
        onClick={() => EventMenu(menu.name)}
      >
        {isOutletCollaborator ? 'Outlet Colaborador' : menu.name}
      </Link>

      {!!menu.submenu && (
        <SubmenuContainer className="submenu" isUserColab={isUserColab}>
          <nav>
            {menu.submenu.map(({ link, name, subcategories }) => (
              <SubmenuItem key={name}>
                <a href={link}>{name}</a>

                <div>
                  {subcategories?.map((subcategory) => (
                    <a key={subcategory.link} href={subcategory.link}>
                      {subcategory.name}
                    </a>
                  ))}
                </div>
              </SubmenuItem>
            ))}

            <ExtraLinksContainer>
              {menu.extraLinks?.map((extraLink) => (
                <SubmenuItem key={extraLink.name}>
                  <a href={extraLink.link}>{extraLink.name}</a>
                </SubmenuItem>
              ))}
            </ExtraLinksContainer>
          </nav>

          <SeeAllContainer>
            <a href={menu.link}>
              Ver tudo em {menu.name}{' '}
              <Icon name="outlined-navigation-arrowleft" color="primary" />
            </a>
          </SeeAllContainer>
        </SubmenuContainer>
      )}
    </ItemWrapper>
  )
}

export default MenuItem
