import type { FC } from 'react'
import React, { useMemo, useState, createContext } from 'react'

export interface IContext {
  isPreHeaderClosed: boolean
  setIsPreHeaderClosed: (value: boolean) => void
}

export const PreHeaderContext = createContext<IContext | undefined>(undefined)

export const PreHeaderProvider: FC = ({ children }) => {
  const [isPreHeaderClosed, setIsPreHeaderClosed] = useState<boolean>(false)

  const value = useMemo(
    () => ({
      isPreHeaderClosed,
      setIsPreHeaderClosed: (val: boolean) => {
        setIsPreHeaderClosed(val)
      },
    }),
    [isPreHeaderClosed]
  )

  return (
    <PreHeaderContext.Provider value={value}>
      {children}
    </PreHeaderContext.Provider>
  )
}
