import React from 'react'
import type { FC } from 'react'

const BlackOnlineStoreIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.583"
    height="14.583"
    viewBox="0 0 14.583 14.583"
  >
    <path
      id="noun-globe-1764219"
      d="M9.792,2.5a7.292,7.292,0,1,0,7.292,7.292A7.3,7.3,0,0,0,9.792,2.5Zm6.217,7.292a6.151,6.151,0,0,1-.246,1.689c-.936-.107-1.934-.184-2.963-.23.046-.491.061-.967.061-1.458s-.015-.967-.061-1.458c1.044-.046,2.026-.123,2.963-.23A6.151,6.151,0,0,1,16.009,9.792ZM9.792,16.009c-.614,0-1.443-1.4-1.811-3.73,1.2-.031,2.425-.031,3.638,0C11.25,14.612,10.406,16.009,9.792,16.009ZM3.575,9.792A6.151,6.151,0,0,1,3.82,8.1c.936.107,1.919.184,2.963.23-.046.491-.061.967-.061,1.458s.015.967.061,1.458c-1.044.046-2.042.123-2.978.23A6.791,6.791,0,0,1,3.575,9.792ZM9.792,3.575c.614,0,1.443,1.4,1.811,3.73-1.2.031-2.441.031-3.623,0C8.349,4.972,9.178,3.575,9.792,3.575Zm2,6.217a13.876,13.876,0,0,1-.061,1.412c-1.274-.031-2.61-.031-3.884,0-.031-.445-.061-.921-.061-1.412a13.876,13.876,0,0,1,.061-1.412c.645.015,1.289.031,1.95.031s1.3-.015,1.95-.031C11.772,8.825,11.787,9.3,11.787,9.792Zm3.577-2.732c-.86.092-1.75.154-2.686.2a9.9,9.9,0,0,0-1.059-3.408A6.18,6.18,0,0,1,15.364,7.059Zm-7.4-3.208A10,10,0,0,0,6.906,7.274c-.936-.046-1.842-.107-2.7-.2A6.226,6.226,0,0,1,7.965,3.851ZM4.2,12.509c.86-.092,1.75-.154,2.7-.2a10,10,0,0,0,1.059,3.423A6.226,6.226,0,0,1,4.2,12.509Zm7.414,3.224a10.013,10.013,0,0,0,1.059-3.408c.936.046,1.827.107,2.686.2A6.18,6.18,0,0,1,11.618,15.733Z"
      transform="translate(-2.5 -2.5)"
      fill="#000"
    />
  </svg>
)

export default BlackOnlineStoreIcon
