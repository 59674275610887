import React from 'react'

import { MenuContainer } from './styled'
import { menu } from './menu'
import MenuItem from './MenuItem'

const MenuDesktop = () => {
  return (
    <MenuContainer>
      {menu.map((item) => (
        <MenuItem key={item.link} menu={item} />
      ))}
    </MenuContainer>
  )
}

export default MenuDesktop
