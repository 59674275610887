import React from 'react'
import { Icon } from '@naturacosmeticos/natds-react'
import { useProfile } from '@vtex/gatsby-theme-store'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'
import { LocalizedLink } from '@vtex/store-ui'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'
import { logoutUser } from 'src/utils'

import { UserButton, UserButtonContainer, UserMenuContainer } from './styled'
import { UserFallback } from './UserFallback'

export const UserClient = () => {
  const profile = useProfile()
  const userName = profile?.firstName?.value ?? 'Cliente'
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'

  const { setIsLoginModalOpen } = useLoginModal()
  const { isRepresentativeSelected, representative } = useRepresentative()

  const handleLogout = async () => {
    logoutUser()

    setTimeout(() => {
      document.location.reload()
    }, 1000)
  }

  if (!isAuthenticated) {
    return <UserFallback onClick={() => setIsLoginModalOpen(true)} />
  }

  return (
    <UserButtonContainer>
      <NavigationMenu.Root>
        <NavigationMenu.Item>
          <NavigationMenu.Trigger asChild>
            <UserButton>
              <Icon
                name="outlined-social-myprofile"
                size="semi"
                color="mediumEmphasis"
              />

              <div>
                <p>Olá, {userName}</p>
                <span>Meus dados</span>
              </div>

              <Icon
                name="outlined-navigation-arrowbottom"
                size="standard"
                className="arrow"
                color="mediumEmphasis"
              />
            </UserButton>
          </NavigationMenu.Trigger>

          <NavigationMenu.Content asChild>
            <UserMenuContainer>
              <NavigationMenu.Link asChild>
                <LocalizedLink
                  to={
                    isRepresentativeSelected
                      ? `/account#/orders?utmi_pc${representative.utmiPc}`
                      : '/account#/orders'
                  }
                >
                  MEUS PEDIDOS
                </LocalizedLink>
              </NavigationMenu.Link>

              <NavigationMenu.Link asChild>
                <LocalizedLink
                  to={
                    isRepresentativeSelected
                      ? `/account#/profile?utmi_pc${representative.utmiPc}`
                      : '/account#/profile'
                  }
                >
                  DADOS PESSOAIS
                </LocalizedLink>
              </NavigationMenu.Link>

              <NavigationMenu.Link asChild>
                <LocalizedLink
                  to={
                    isRepresentativeSelected
                      ? `/account#/addresses?utmi_pc${representative.utmiPc}`
                      : '/account#/addresses'
                  }
                >
                  ENDEREÇOS CADASTRADOS
                </LocalizedLink>
              </NavigationMenu.Link>

              <NavigationMenu.Link href="/wishlist" asChild>
                <LocalizedLink to="/wishlist">FAVORITOS</LocalizedLink>
              </NavigationMenu.Link>

              <NavigationMenu.Link asChild>
                <button onClick={handleLogout}>SAIR</button>
              </NavigationMenu.Link>
            </UserMenuContainer>
          </NavigationMenu.Content>
        </NavigationMenu.Item>
      </NavigationMenu.Root>
    </UserButtonContainer>
  )
}
