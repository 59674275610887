import React from 'react'
import type { FC } from 'react'

const CommonQuestionSecondStep: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="189.6"
    height="162.805"
    viewBox="0 0 189.6 162.805"
  >
    <g transform="translate(-65.2 -1369)">
      <path
        style={{
          stroke: '#fff',
          strokeWidth: '2px',
          fill: '#e0e0e0',
        }}
        d="M175.7,3.5H14.9A13.4,13.4,0,0,0,1.5,16.9V124.1a13.4,13.4,0,0,0,13.4,13.4H71.984a22.958,22.958,0,0,1-10.095,8.933c-4.109,2.5-8.04,4.824-8.933,8.933a8.933,8.933,0,0,0,3.037,7.415,4.467,4.467,0,0,0,3.573,1.519h71.466a4.467,4.467,0,0,0,3.127-1.34,8.933,8.933,0,0,0,3.037-7.415c-.536-4.288-4.467-6.611-8.933-8.933a22.958,22.958,0,0,1-10.095-8.933H175.7A13.4,13.4,0,0,0,189.1,124.1V16.9A13.4,13.4,0,0,0,175.7,3.5ZM14.9,12.433H175.7a4.467,4.467,0,0,1,4.467,4.467v93.8H10.433V16.9A4.467,4.467,0,0,1,14.9,12.433ZM124.065,154.2l1.965,1.161H64.569l1.965-1.161A27.336,27.336,0,0,0,81.453,137.5h27.782A27.336,27.336,0,0,0,124.065,154.2ZM175.7,128.566H14.9a4.467,4.467,0,0,1-4.467-4.467v-4.467H180.166V124.1A4.467,4.467,0,0,1,175.7,128.566Z"
        transform="translate(64.7 1366.5)"
      />
      <path
        style={{
          fill: '#e0e0e0',
        }}
        d="M39.011,14.772a1.474,1.474,0,0,0-1.111-.507H32V11.317a10.317,10.317,0,1,0-20.635,0v2.948h-5.9a1.474,1.474,0,0,0-1.459,1.677L7.7,42.473a1.474,1.474,0,0,0,1.459,1.271H34.215a1.474,1.474,0,0,0,1.459-1.271l3.685-26.53A1.474,1.474,0,0,0,39.011,14.772ZM14.317,11.317a7.37,7.37,0,0,1,14.739,0v2.948H14.317ZM32.931,40.8H10.442L7.166,17.213h4.2v1.474a1.474,1.474,0,1,0,2.948,0V17.213H29.056v1.474a1.474,1.474,0,1,0,2.948,0V17.213h4.2Z"
        transform="translate(90 1388.3)"
      />
      <g transform="translate(6.5 5)">
        <g transform="translate(-670 844.13)">
          <g
            style={{
              fill: '#f49ac1',
              stroke: '#f49ac1',
            }}
            transform="translate(769 568.87)"
          >
            <rect
              style={{
                stroke: 'none',
              }}
              width="121"
              height="37"
              rx="18.5"
            />
            <rect
              style={{
                fill: 'none',
              }}
              x="0.5"
              y="0.5"
              width="120"
              height="36"
              rx="18"
            />
          </g>
          <text
            style={{
              fill: '#fff',
              fontSize: '12px',
              fontFamily: 'Lato-Bold, Lato',
            }}
            transform="translate(791 591.87)"
          >
            <tspan x="0" y="0">
              Fechar pedido
            </tspan>
          </text>
        </g>
        <g transform="translate(201.4 1438.602)">
          <path
            style={{
              fillRule: 'evenodd',
            }}
            d="M0,0,12,6.955,7.091,8.386,10.773,13.7,9.2,14.8,5.455,9.477l-3,4.159Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default CommonQuestionSecondStep
