import { styled } from 'src/gatsby-theme-stitches/config'

export const FooterContainer = styled('div', {
  backgroundColor: '$highlight',
  display: 'flex',
  color: '#fff',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'left',
  padding: '1.25rem',
  fontFamily: '$roboto',

  '@sm': { padding: '0', textAlign: 'center' },
})

export const FooterSectionsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '85vw',
  maxWidth: '80rem',
})

export const NavContainer = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',

  '@sm': {
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },

  '> div': {
    '@sm': {
      margin: '.5rem',
    },
  },
})

export const SocialMediaContainer = styled('div', {
  p: { fontSize: '0.75rem' },

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  mt: '0.875rem',
  padding: '1.25rem',

  '@sm': {
    mt: '1rem',
    padding: '0',
    alignItems: 'center',
  },
})

export const PaymentIconsContainer = styled('div', {
  p: { fontSize: '0.75rem' },

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  mt: '0.875rem',
  width: '42%',
  padding: '1.25rem',

  '@sm': {
    mt: '1rem',
    padding: '0',
    alignItems: 'center',
    width: '100%',
  },
})

export const SocialMediaIcons = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  mt: '0.5rem',
  alignItems: 'center',

  '@sm': { width: '100%' },

  svg: {
    path: {
      fill: '$white',
    },
  },
})

export const PaymentIcons = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  mt: '0.5rem',
  alignItems: 'center',

  '@sm': {
    width: '100%',
  },

  svg: {
    size: '2rem',
  },
})

export const CopyrightContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '1.25rem',
  width: '100%',
})

export const NavTexts = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  a: {
    fontSize: '0.75rem',
    textDecoration: 'none',
    color: '$onPrimary',
    marginBottom: '.5rem',
  },
})

export const UpSideContainer = styled('div', {
  display: 'flex',
  margin: '1rem',
  width: '100%',
  justifyContent: 'space-between',

  '@sm': {
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
})

export const LogoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  p: {
    fontSize: '0.75rem',
    mt: '.625rem',
    width: '20rem',
  },
})

export const CopyrightTopText = styled('div', {
  p: {
    fontSize: '0.875rem',
  },

  mb: '.8125rem',
})

export const CopyrightBottomText = styled('div', {
  p: { fontSize: '0.75rem' },
})

export const CertificatesContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '25%',

  '@sm': {
    width: '100%',
  },
})

export const UpSideCertificates = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@sm': {
    alignItems: 'center',
  },
})

export const IsoCertificates = styled('div', {
  display: 'flex',
  mt: '.5rem',
  height: '3.5rem',
  alignItems: 'center',

  '@sm': {
    mt: '.5rem',
    justifyContent: 'center',
  },

  img: {
    height: '100%',
  },

  svg: {
    width: 'auto',
    height: '5rem',
  },
})

export const GoogleCertificate = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mt: '1rem',
  width: '16rem',
})

export const RealizationsContainer = styled('div', {
  display: 'flex',
  mt: '2rem',

  '@sm': {
    justifyContent: 'center',
  },
})

export const EnextLogoContainer = styled('div', {
  display: 'flex',
  gap: '.3125rem',
  ml: '.25rem',
  alignItems: 'center',

  img: {
    mt: '1rem',
  },

  '@sm': {
    mt: '.4375rem',
  },
})

export const VtexLogoContainer = styled('div', {
  svg: {
    height: 'auto',
    width: '5.625rem',

    '@sm': {
      height: '4.375rem',
    },
  },
})

export const Paragraph = styled('p', {
  fontWeight: '$regular',
  fontSize: '.75rem',

  variants: {
    navTitle: {
      true: {
        fontWeight: '$medium',
        mb: '1rem',
      },
    },
  },
})
