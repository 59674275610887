import React from 'react'
import { Icon } from '@naturacosmeticos/natds-react'
// import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'
// import { SuspenseSSR } from '@vtex/store-ui'

import { RepresentativeMenuContainer } from './styled'

const RepresentativeMenu = () => {
  // const {
  //   isRepresentativeSelected,
  //   toggleSearchRepresentativePopup,
  //   toggleSelectedRepresentativePopup,
  //   representative,
  // } = useRepresentative()

  // const representativeName =
  //   isRepresentativeSelected &&
  //   (representative.nome
  //     ? representative.nome
  //     : representative.nomeRevendedora?.split(' ')[0].toLocaleUpperCase())

  // const representativeButtonText = isRepresentativeSelected
  //   ? `Representante ${representativeName}`
  //   : 'Encontre um(a) Representante'

  // const handleRepresentativeButtonClick = () => {
  //   if (isRepresentativeSelected) {
  //     return toggleSelectedRepresentativePopup(true)
  //   }

  //   return toggleSearchRepresentativePopup(true)
  // }

  return (
    <RepresentativeMenuContainer>
      <nav>
        <div>
          <ul className="list">
            <li>
              <a href="/institucional/a-avon">A Avon</a>
            </li>

            <li>
              <a
                href="https://institutoavon.org.br/"
                target="_blank"
                rel="noreferrer"
              >
                Instituto Avon
              </a>
            </li>

            <li>
              <a href="/institucional/carreira">Carreiras</a>
            </li>
          </ul>
        </div>
      </nav>

      <div>
        <a
          href="https://www.avoncomigo.avon.com.br/widget/avonwg2/#/login"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="filled-brand-avonshorthand" />
          Já sou Consultora
        </a>
        <a href="https://accounts.natura-avon.com/queroserconsultora?utm_source=avon&utm_medium=site&utm_term=botao_topo&utm_content=perene&utm_campaign=avon_site_institucional_botao_topo">
          <Icon name="outlined-finance-suitcase" />
          Quero ser Consultora
        </a>

        {/* <button onClick={handleRepresentativeButtonClick}>
          <Icon name="outlined-place-local" />

          <SuspenseSSR fallback={<p>Encontre um(a) Representante</p>}>
            <p>{representativeButtonText}</p>
          </SuspenseSSR>
        </button> */}
      </div>
    </RepresentativeMenuContainer>
  )
}

export default RepresentativeMenu
