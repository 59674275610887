import React from 'react'
import { useUISlider } from '@vtex/store-ui'
import { useColabStore } from 'src/hooks/useColabStore'

import { PreHeaderContainer, SliderContainer, SliderText } from './styled'

const allItems = [
  {
    label: (
      <p>
        <strong>FRETE GRÁTIS</strong>{' '}
        <span>
          acima de R$119,90 para as regiões Sul, Sudeste e para todo o estado de
          Goiás
        </span>
      </p>
    ),
  },
  {
    label: (
      <p>
        <strong>FRETE GRÁTIS</strong>{' '}
        <span>acima de R$220,00 para as demais regiões</span>
      </p>
    ),
  },
  {
    label: (
      <p>
        <span>Parcele até</span> <strong>6X SEM JUROS</strong>
      </p>
    ),
  },
]

const PreHeader = () => {
  const { isUserColab } = useColabStore()
  const { items } = useUISlider({
    allItems,
    pageSize: 1,
    autoplay: 4500,
  })

  const [{ label }] = items

  if (isUserColab) {
    return null
  }

  return (
    <PreHeaderContainer>
      <SliderContainer>
        <SliderText>{label}</SliderText>
      </SliderContainer>
    </PreHeaderContainer>
  )
}

export default PreHeader
