import { Button, Icon } from '@naturacosmeticos/natds-react'
import { useOrderForm } from '@vtex/gatsby-theme-store'
import { useGlobalUIState } from '@vtex/store-sdk'
import { navigate } from 'gatsby'
import React, { useEffect, useMemo } from 'react'
import type { OrderFormProduct } from 'src/typings'
import { formatPrice } from 'src/utils/formatPrice'

import Product from './Product'
import {
  MinicartContainer,
  MinicartFooter,
  MinicartHeader,
  MinicartOverlay,
  MinicartProducts,
} from './styled'

const Minicart = () => {
  const { displayMinicart, closeMinicart } = useGlobalUIState()
  const { orderForm } = useOrderForm()

  useEffect(() => {
    const handleCloseOnEscape = (event: KeyboardEvent) => {
      event.key === 'Escape' && closeMinicart()
    }

    window.addEventListener('keydown', handleCloseOnEscape)
  }, [closeMinicart])

  const products = orderForm.items.filter(
    (product) => !product.isGift
  ) as unknown as OrderFormProduct[]

  const priceInfo = useMemo(() => {
    const productsPrice =
      !!products &&
      products.reduce((acc: number, { listPrice, quantity }) => {
        return Number(acc) + Number(listPrice) * quantity
      }, 0)

    const discountPrice =
      !!products &&
      products.reduce((acc: number, { sellingPrice, listPrice, quantity }) => {
        return (
          Number(acc) + (Number(sellingPrice) - Number(listPrice)) * quantity
        )
      }, 0)

    const subtotal = productsPrice + discountPrice
    const shippingValue = orderForm.shipping.deliveryOptions[0]?.price ?? 0
    const total = orderForm.value

    return {
      productsPrice: formatPrice(productsPrice / 100),
      discountPrice: formatPrice(discountPrice / 100),
      subtotal: formatPrice(subtotal / 100),
      shippingValue: formatPrice(shippingValue / 100),
      total: formatPrice(total / 100),
    }
  }, [orderForm.shipping.deliveryOptions, orderForm.value, products])

  if (!displayMinicart || orderForm.id === 'default-order-form') {
    return null
  }

  const handleCheckout = () => {
    return navigate('/checkout')
  }

  return (
    <>
      <MinicartOverlay onClick={closeMinicart} />

      <MinicartContainer>
        <MinicartHeader>
          <p>MINHA SACOLA</p>

          <button onClick={closeMinicart}>
            <Icon name="outlined-navigation-close" size="standard" />
          </button>
        </MinicartHeader>

        <MinicartProducts>
          {products.map((product) => (
            <Product key={product.id} product={product} />
          ))}
        </MinicartProducts>

        <MinicartFooter>
          <div className="price-container">
            <div>
              <p>Valor da compra</p>
              <p>{priceInfo.productsPrice}</p>
            </div>

            <div className="discount">
              <p>Descontos</p>
              <p>{priceInfo.discountPrice}</p>
            </div>

            <div>
              <p>Subtotal</p>
              <p>{priceInfo.subtotal}</p>
            </div>

            <div>
              <p>Entrega (Normal)</p>
              <p>{priceInfo.shippingValue}</p>
            </div>

            <div className="total">
              <p>Total</p>
              <p>{priceInfo.total}</p>
            </div>
          </div>

          <Button fullWidth onClick={handleCheckout}>
            FINALIZAR COMPRA
          </Button>
        </MinicartFooter>
      </MinicartContainer>
    </>
  )
}

export default Minicart
