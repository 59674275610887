import { createStitches, globalCss } from '@stitches/react'

import { theme } from '../themes'

export const { styled, css, getCssText } = createStitches({
  theme: {
    colors: {
      ...theme.color,
      white: '#FFF',
      black: '#000',
    },
    fonts: {
      montserrat: 'Montserrat, sans-serif',
      roboto: 'Roboto, sans-serif',
      arial: 'Arial, sans-serif',
      lato: 'Lato, sans-serif',
    },
    fontSizes: {
      1: '.8125rem',
      2: '.875rem',
      3: '1rem',
      4: '1.125rem',
      5: '1.25rem',
      6: '1.4375rem',
      7: '1.625rem',
      8: '1.8125rem',
      9: '2rem',
      10: '2.25rem',
      11: '2.5625rem',
      12: '2.875rem',
      13: '3.25rem',
      14: '3.625rem',
      15: '4.125rem',
      16: '4.625rem',
      17: '5.1875rem',
    },
    fontWeights: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    lineHeights: { ...theme.typography.lineHeight },
    radii: { ...theme.borderRadius, rounded: '50%' },
    sizes: { ...theme.size },
    space: { ...theme.spacing },
  },
  media: {
    onlyMobile: '(max-width: 768px)', // Mobile
    sm: '(max-width: 1024px)', // Smartphones
    lg: '(min-width: 1025px)', // PC
  },
  utils: {
    // Margin
    mt: (value: string) => ({
      marginTop: value,
    }),
    mr: (value: string) => ({
      marginRight: value,
    }),
    mb: (value: string) => ({
      marginBottom: value,
    }),
    ml: (value: string) => ({
      marginLeft: value,
    }),
    mx: (value: string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: string) => ({
      marginTop: value,
      marginBottom: value,
    }),

    // Padding
    pt: (value: string) => ({
      paddingTop: value,
    }),
    pr: (value: string) => ({
      paddingRight: value,
    }),
    pb: (value: string) => ({
      paddingBottom: value,
    }),
    pl: (value: string) => ({
      paddingLeft: value,
    }),
    px: (value: string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    // A property for applying width/height together
    size: (value: string) => ({
      width: value,
      height: value,
    }),

    // A property to apply linear gradient
    linearGradient: (value: string) => ({
      backgroundImage: `linear-gradient(${value})`,
    }),

    // An abbreviated property for border-radius
    br: (value: string) => ({
      borderRadius: value,
    }),
  },
})

export const globalStyles = globalCss({
  '@import': [
    "url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap')",
    "url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap')",
  ],

  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },

  body: {
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    'text-rendering': 'optimizeLegibility',
  },

  'input[type=search]::-ms-clear': { display: 'none', width: 0, height: 0 },
  'input[type=search]::-ms-reveal': { display: 'none', width: 0, height: 0 },

  'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration':
    { display: 'none' },

  ul: {
    listStyleType: 'none',
  },

  '.slider-arrow': {
    '@media (max-width: 1023px)': {
      display: 'none',
    },

    button: {
      position: 'absolute',
      top: '44%',
      transform: 'translate(0, -50%)',
      cursor: 'pointer',
      backgroundColor: '$white',
      boxShadow: '0px 4px 5px #00000024',
      border: '1px solid $colors$primary',
      borderRadius: '50%',
      width: '3rem',
      height: '3rem',
      display: 'grid',
      placeItems: 'center',
      transition: 'all 0.2s',

      '&:first-of-type': {
        left: '-1.5rem',
      },

      '&:last-of-type': {
        right: '-1.5rem',
      },

      '&:hover': {
        filter: 'brightness(0.75)',
      },
    },
  },
})
