import React from 'react'
import { SuspenseViewport } from '@vtex/store-ui'
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  PinterestIcon,
  TwitterIcon,
  YoutubeIcon,
} from 'src/components/icons'

import { SocialMediaContainer, SocialMediaIcons } from './styled'

const SocialMedia = () => {
  return (
    <SocialMediaContainer>
      <p>Siga a gente nas nossas redes sociais</p>

      <SuspenseViewport fallback={null}>
        <SocialMediaIcons>
          <InstagramIcon />
          <TwitterIcon />
          <FacebookIcon />
          <PinterestIcon />
          <YoutubeIcon />
          <LinkedinIcon />
        </SocialMediaIcons>
      </SuspenseViewport>
    </SocialMediaContainer>
  )
}

export default SocialMedia
