import type { IconName } from '@naturacosmeticos/natds-icons'

export interface MenuItem {
  name: string
  link: string
  color?: string
  icon: IconName
  submenu?: Array<{
    name: string
    link: string
    subcategories?: Array<{
      name: string
      link: string
    }>
  }>
  extraLinks?: Array<{ name: string; link: string }>
}

export const menu: MenuItem[] = [
  {
    name: 'Lançamentos',
    link: '/novidades',
    icon: 'outlined-communication-speaker',
  },
  {
    name: 'Kits e Presentes',
    link: '/kits-e-presentes',
    icon: 'outlined-content-gift',
    submenu: [
      {
        name: 'Kits/Presentes',
        link: '/kits-e-presentes',
        subcategories: [
          { name: 'Tratamentos', link: '/kits-e-presentes/tratamentos' },
          { name: 'Maquiagens', link: '/kits-e-presentes/maquiagens' },
          { name: 'Rosto', link: '/kits-e-presentes/rosto' },
          { name: 'Corpo e Banho', link: '/kits-e-presentes/corpo-e-banho' },
          { name: 'Perfumaria', link: '/kits-e-presentes/fragrancias' },
          { name: 'Cabelo', link: '/kits-e-presentes/cabelos' },
          { name: 'Embalagens', link: '/kits-e-presentes/embalagens' },
        ],
      },
    ],
  },
  {
    name: 'Maquiagem',
    link: '/maquiagens',
    icon: 'outlined-product-makeup',
    submenu: [
      {
        name: 'Rosto',
        link: '/maquiagens/rosto',
        subcategories: [
          {
            name: 'Primer',
            link: '/maquiagens/rosto/primer',
          },
          {
            name: 'Base',
            link: '/maquiagens/rosto/base',
          },
          {
            name: 'Corretivo',
            link: '/maquiagens/rosto/corretivo',
          },
          {
            name: 'Pó',
            link: '/maquiagens/rosto/po',
          },
          {
            name: 'Blush',
            link: '/maquiagens/rosto/blush',
          },
          {
            name: 'Iluminador',
            link: '/maquiagens/rosto/iluminador',
          },
        ],
      },
      {
        name: 'Olhos',
        link: '/maquiagens/olhos',
        subcategories: [
          {
            name: 'Máscara para cílios',
            link: '/maquiagens/olhos/mascara-para-cilios',
          },
          {
            name: 'Lápis para olhos',
            link: '/maquiagens/olhos/lapis-para-olhos',
          },
          {
            name: 'Sombra',
            link: '/maquiagens/olhos/sombra',
          },
          {
            name: 'Delineador',
            link: '/maquiagens/olhos/delineador',
          },
          {
            name: 'Sobrancelha',
            link: '/maquiagens/olhos/sobrancelha',
          },
        ],
      },
      {
        name: 'Boca',
        link: '/maquiagens/boca',
        subcategories: [
          {
            name: 'Batom',
            link: '/maquiagens/boca/batom',
          },
          {
            name: 'Gloss Labial',
            link: '/maquiagens/boca/gloss-labial',
          },
          {
            name: 'Lip Balm',
            link: '/maquiagens/boca/lip-balm',
          },
        ],
      },
      {
        name: 'Acessórios',
        link: '/maquiagens/acessorios',
        subcategories: [
          {
            name: 'Pincel',
            link: '/maquiagens/acessorios/pincel',
          },
          {
            name: 'Nécessaire',
            link: '/maquiagens/acessorios/necessaire',
          },
        ],
      },
      {
        name: 'Unhas',
        link: '/unhas',
        subcategories: [
          {
            name: 'Esmalte',
            link: '/unhas/esmaltes/esmalte',
          },
          {
            name: 'Base para Unhas',
            link: '/unhas/esmaltes/base-para-unhas',
          },
        ],
      },
      {
        name: 'Kits',
        link: '/maquiagens/kits',
      },
    ],
    extraLinks: [
      {
        name: 'Cuidado com as Unhas',
        link: '/unhas/cuidados-com-as-unhas',
      },
      {
        name: 'Acessórios para as Unhas',
        link: '/unhas/acessorios',
      },
    ],
  },
  {
    name: 'Rosto',
    link: '/rosto',
    icon: 'outlined-product-face',
    submenu: [
      {
        name: 'Cuidados com o Rosto',
        link: '/rosto/cuidados',
        subcategories: [
          {
            name: 'Limpeza Facial',
            link: '/rosto/cuidados/limpeza',
          },
          {
            name: 'Esfoliante',
            link: '/rosto/cuidados/esfoliante',
          },
          {
            name: 'Máscara Facial',
            link: '/rosto/cuidados/mascara',
          },
          {
            name: 'Sérum e Superconcentrados',
            link: '/rosto/cuidados/serum',
          },
          {
            name: 'Hidratante Facial',
            link: '/rosto/cuidados/hidratante',
          },
          {
            name: 'Cremes Diários Antissinais',
            link: '/rosto/cuidados/diarios',
          },
          {
            name: 'Área dos Olhos',
            link: '/rosto/cuidados/olhos',
          },
          {
            name: 'Protetor Solar Facial',
            link: '/rosto/cuidados/protetor-solar',
          },
          {
            name: 'Demaquilante',
            link: '/rosto/cuidados/demaquilante',
          },
        ],
      },
      {
        name: 'Tratamentos Faciais',
        link: '/rosto/tratamentos',
        subcategories: [
          {
            name: 'Antissinais',
            link: '/rosto/tratamentos/antissinais',
          },
          {
            name: 'Poros Dilatados, Cravos e Espinhas',
            link: '/rosto/tratamentos/poros-cravos-espinhas',
          },
          {
            name: 'Uniformizador de Tom',
            link: '/rosto/tratamentos/uniformizador',
          },
          {
            name: 'Antioxidante',
            link: '/rosto/tratamentos/antioxidante',
          },
        ],
      },
    ],
    extraLinks: [
      {
        name: 'Descubra seu Passo a Passo',
        link: '/quiz-pele',
      },
      {
        name: 'Kits de Skincare e Presentes',
        link: '/kits-e-presentes/rosto',
      },
      {
        name: 'Acessórios de Beleza para o Rosto',
        link: '/rosto/acessorios',
      },
      {
        name: 'Saiba mais sobre Skincare',
        link: '/skin-care',
      },
    ],
  },
  {
    name: 'Corpo e Banho',
    link: '/corpo-e-banho',
    icon: 'outlined-product-dailycare',
    submenu: [
      {
        name: 'Cuidados com o Corpo',
        link: '/corpo-e-banho/cuidados',
        subcategories: [
          {
            name: 'Sabonete Íntimo',
            link: '/corpo-e-banho/cuidados/sabonete-intimo',
          },
          {
            name: 'Esfoliante Corporal',
            link: '/corpo-e-banho/cuidados/esfoliante',
          },
          {
            name: 'Óleo Corporal',
            link: '/corpo-e-banho/cuidados/oleo',
          },
          {
            name: 'Spray Corporal',
            link: '/corpo-e-banho/cuidados/spray',
          },
          {
            name: 'Hidratante Corporal',
            link: '/corpo-e-banho/cuidados/hidratante',
          },
          {
            name: 'Creme Depilatório',
            link: '/corpo-e-banho/cuidados/depilatorio',
          },
        ],
      },
      {
        name: 'Cuidados com as Mãos',
        link: '/corpo-e-banho/cuidados-com-as-maos',
        subcategories: [
          {
            name: 'Sabonete para as Mãos',
            link: '/corpo-e-banho/cuidados-com-as-maos/sabonete',
          },
          {
            name: 'Creme para as Mãos',
            link: '/corpo-e-banho/cuidados-com-as-maos/creme',
          },
        ],
      },
      {
        name: 'Cuidado com os Pés',
        link: '/corpo-e-banho/cuidados-com-os-pes',
        subcategories: [
          {
            name: 'Creme para os Pés',
            link: '/corpo-e-banho/cuidados-com-os-pes/creme',
          },
          {
            name: 'Spray e Sérum para os Pés',
            link: '/corpo-e-banho/cuidados-com-os-pes/spray-serum',
          },
          {
            name: 'Esfoliante para os Pés',
            link: '/corpo-e-banho/cuidados-com-os-pes/esfoliante',
          },
        ],
      },
      {
        name: 'Desodorante',
        link: '/corpo-e-banho/desodorante',
        subcategories: [
          {
            name: 'Desodorante Roll-On',
            link: '/corpo-e-banho/desodorante/roll-on',
          },
          {
            name: 'Desodorante Aerossol',
            link: '/corpo-e-banho/desodorante/aerossol',
          },
          {
            name: 'Desodorante Spray',
            link: '/corpo-e-banho/desodorante/spray',
          },
          {
            name: 'Desodorante Spray Corporal',
            link: '/corpo-e-banho/desodorante/spray-corporal',
          },
          {
            name: 'Desodorante Creme em Pote',
            link: '/corpo-e-banho/desodorante/creme-pote',
          },
          {
            name: 'Desodorante Creme em Bisnaga',
            link: '/corpo-e-banho/desodorante/creme-bisnaga',
          },
        ],
      },
      {
        name: 'Infantil',
        link: '/corpo-e-banho/infantil',
        subcategories: [
          {
            name: 'Protetor Solar',
            link: '/corpo-e-banho/infantil/protetor-solar',
          },
          {
            name: 'Sabonete de Banho',
            link: '/corpo-e-banho/infantil/sabonete',
          },
          {
            name: 'Loção Hidratante',
            link: '/corpo-e-banho/infantil/hidratante',
          },
        ],
      },
    ],
    extraLinks: [
      {
        name: 'Proteção Solar',
        link: '/corpo-e-banho/protecao-solar',
      },
      {
        name: 'Kits de Corpo e Banho',
        link: '/corpo-e-banho/kits',
      },
    ],
  },
  {
    name: 'Perfumaria',
    link: '/fragrancias',
    icon: 'outlined-product-perfumery',
    submenu: [
      {
        name: 'Perfumaria',
        link: '/fragrancias',
        subcategories: [
          {
            name: 'Kits',
            link: '/fragrancias/kits',
          },
          {
            name: 'Feminino',
            link: '/fragrancias/feminino',
          },
          {
            name: 'Masculino',
            link: '/fragrancias/masculino',
          },
          {
            name: 'Infantil',
            link: '/fragrancias/infantil',
          },
        ],
      },
    ],
  },
  {
    name: 'Casa & Estilo',
    link: '/moda-e-casa',
    icon: 'outlined-product-fashion',
    submenu: [
      {
        name: 'Casa',
        link: '/moda-e-casa/casa',
        subcategories: [
          {
            name: 'Cuidados Pessoais',
            link: '/moda-e-casa/casa/cuidados-pessoais',
          },
          {
            name: 'Infantil',
            link: '/moda-e-casa/casa/infantil',
          },
          {
            name: 'Casa & Cozinha',
            link: '/moda-e-casa/casa/casa-cozinha',
          },
          {
            name: 'Coleção Disney Casa',
            link: '/disney-casa',
          },
        ],
      },
      {
        name: 'Estilo',
        link: '/moda-e-casa/moda',
        subcategories: [
          {
            name: 'Vestuário Feminino',
            link: '/moda-e-casa/moda/vestuario-feminino',
          },
          {
            name: 'Calçados',
            link: '/moda-e-casa/moda/calcados',
          },
          {
            name: 'Acessórios',
            link: '/moda-e-casa/moda/acessorios',
          },
        ],
      },
    ],
  },
  {
    name: 'Cabelos',
    link: '/cabelos',
    icon: 'outlined-product-hair',
    submenu: [
      {
        name: 'Cuidados com o Cabelo',
        link: '/cabelos/cuidados',

        subcategories: [
          {
            name: 'Shampoo',
            link: '/cabelos/cuidados/shampoo',
          },
          {
            name: 'Condicionador',
            link: '/cabelos/cuidados/condicionador',
          },
          {
            name: 'Sérum e Óleo Capilar',
            link: '/cabelos/cuidados/serum-oleo',
          },
          {
            name: 'Creme para Pentear',
            link: '/cabelos/cuidados/creme-pentear',
          },
          {
            name: 'Leave-in',
            link: '/cabelos/cuidados/leave-in',
          },
          {
            name: 'Máscara Capilar',
            link: '/cabelos/cuidados/mascara',
          },
        ],
      },
      {
        name: 'Tipos de Cuidados',
        link: '/tratamentos/tratamento-capilar',
        subcategories: [
          {
            name: 'Restaurador',
            link: '/tratamentos/tratamento-capilar/restaurador',
          },
          {
            name: 'Antiqueda',
            link: '/tratamentos/tratamento-capilar/antiqueda',
          },
          {
            name: 'Hidratante',
            link: '/tratamentos/tratamento-capilar/hidratante',
          },
          {
            name: 'Nutrição',
            link: '/tratamentos/tratamento-capilar/nutricao',
          },
          {
            name: 'Anticaspa',
            link: '/tratamentos/tratamento-capilar/anticaspa',
          },
        ],
      },
      {
        name: 'Infantil',
        link: '/cabelos/infantil',
        subcategories: [
          {
            name: 'Shampoo e Condicionador',
            link: '/cabelos/infantil/shampoo-condicionador',
          },
        ],
      },
      {
        name: 'Acessórios para Cabelo',
        link: '/cabelos/acessorios',
        subcategories: [
          {
            name: 'Escova de Cabelo',
            link: '/cabelos/acessorios/escovas',
          },
        ],
      },
    ],
    extraLinks: [
      {
        name: 'Coloração',
        link: '/cabelos/coloracao',
      },
      {
        name: 'Kits para Cabelo',
        link: '/cabelos/kits',
      },
    ],
  },
  {
    name: 'Outlet',
    link: '/outlet',
    icon: 'outlined-product-outlet',
  },
  {
    name: 'Promoções',
    link: '/ofertas',
    icon: 'outlined-product-promotionproduct',
  },
]
