import React from 'react'
import type { FC } from 'react'

const CommonQuestionFourthStep: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="189.6"
    height="162.805"
    viewBox="0 0 189.6 162.805"
  >
    <g transform="translate(-65.2 -1369)">
      <path
        style={{
          fill: '#e0e0e0',
          stroke: '#fff',
          strokeWidth: '2px',
        }}
        d="M175.7,3.5H14.9A13.4,13.4,0,0,0,1.5,16.9V124.1a13.4,13.4,0,0,0,13.4,13.4H71.984a22.958,22.958,0,0,1-10.095,8.933c-4.109,2.5-8.04,4.824-8.933,8.933a8.933,8.933,0,0,0,3.037,7.415,4.467,4.467,0,0,0,3.573,1.519h71.466a4.467,4.467,0,0,0,3.127-1.34,8.933,8.933,0,0,0,3.037-7.415c-.536-4.288-4.467-6.611-8.933-8.933a22.958,22.958,0,0,1-10.095-8.933H175.7A13.4,13.4,0,0,0,189.1,124.1V16.9A13.4,13.4,0,0,0,175.7,3.5ZM14.9,12.433H175.7a4.467,4.467,0,0,1,4.467,4.467v93.8H10.433V16.9A4.467,4.467,0,0,1,14.9,12.433ZM124.065,154.2l1.965,1.161H64.569l1.965-1.161A27.336,27.336,0,0,0,81.453,137.5h27.782A27.336,27.336,0,0,0,124.065,154.2ZM175.7,128.566H14.9a4.467,4.467,0,0,1-4.467-4.467v-4.467H180.166V124.1A4.467,4.467,0,0,1,175.7,128.566Z"
        transform="translate(64.7 1366.5)"
      />
      <g transform="translate(320.837 1182.019)">
        <g transform="translate(-182 218.9)">
          <path
            style={{
              fill: '#3c3c3c',
            }}
            d="M-175.116,275.631h27.1c4.363,0,7.884-3.138,7.884-6.965l-3.215-36.2h-7.731v-.077A10.453,10.453,0,0,0-161.569,221.9a10.453,10.453,0,0,0-10.486,10.486v.077h-7.731L-183,268.59v.076C-183,272.493-179.479,275.631-175.116,275.631Zm5.587-43.245a8,8,0,0,1,7.96-7.96,8,8,0,0,1,7.96,7.96v.077l-15.92-.077Zm-7.96,2.6h5.434v4.745h2.526v-4.745h15.844v4.745h2.526v-4.745h5.434l2.985,33.754c0,2.449-2.449,4.363-5.358,4.363h-27.1c-2.909,0-5.358-1.99-5.358-4.363Zm7.195,27.631,15.691-15.691,1.837,1.837-15.691,15.691Zm11.4-4.975a4.574,4.574,0,0,0,0,6.429h0a4.449,4.449,0,0,0,3.215,1.3,4.645,4.645,0,0,0,3.215-1.3,4.574,4.574,0,0,0,0-6.429,4.512,4.512,0,0,0-3.215-1.3A4.512,4.512,0,0,0-158.89,257.644Zm4.669,4.669a2.033,2.033,0,0,1-2.832,0,2.033,2.033,0,0,1,0-2.832,2.033,2.033,0,0,1,2.832,0A2.033,2.033,0,0,1-154.221,262.313Zm-16.456-8.572a4.449,4.449,0,0,0,3.215,1.3,4.646,4.646,0,0,0,3.215-1.3,4.574,4.574,0,0,0,0-6.429,4.574,4.574,0,0,0-6.429,0,4.574,4.574,0,0,0,0,6.429Zm1.76-4.669a2.033,2.033,0,0,1,2.832,0,2.033,2.033,0,0,1,0,2.832,2.033,2.033,0,0,1-2.832,0A2.033,2.033,0,0,1-168.917,249.072Z"
            transform="translate(183 -221.9)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default CommonQuestionFourthStep
