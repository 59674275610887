import React from 'react'
import type { FC } from 'react'
import { Link } from '@vtex/store-ui'

const InstagramIcon: FC = () => (
  <Link
    href="https://www.instagram.com/avonbrasil/"
    target="_blank"
    aria-label="Instagram Icon"
    title="Instagram Icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              d="M9.997 0c2.715 0 3.055.012 4.121.06 1.065.049 1.791.218 2.427.465a4.9 4.9 0 0 1 1.77 1.153 4.9 4.9 0 0 1 1.154 1.77c.247.636.416 1.363.465 2.427.048 1.066.06 1.407.06 4.122s-.012 3.055-.06 4.122c-.049 1.064-.218 1.79-.465 2.426a4.9 4.9 0 0 1-1.153 1.77 4.9 4.9 0 0 1-1.77 1.154c-.637.247-1.363.416-2.428.465-1.066.048-1.406.06-4.121.06-2.715 0-3.056-.012-4.122-.06-1.064-.049-1.79-.218-2.427-.465a4.9 4.9 0 0 1-1.77-1.153 4.9 4.9 0 0 1-1.153-1.77c-.247-.637-.416-1.363-.465-2.427C.011 13.052 0 12.712 0 9.997S.011 6.94.06 5.875C.11 4.811.278 4.085.525 3.45a4.9 4.9 0 0 1 1.153-1.771A4.9 4.9 0 0 1 3.448.525C4.084.278 4.811.109 5.875.06 6.941.012 7.282 0 9.997 0m0 1.802c-2.67 0-2.985.01-4.04.058-.974.045-1.503.208-1.855.345-.467.18-.8.398-1.15.747-.35.35-.566.683-.747 1.15-.137.352-.3.881-.345 1.856-.048 1.054-.058 1.37-.058 4.039 0 2.669.01 2.985.058 4.039.045.975.208 1.504.345 1.856.18.467.398.8.747 1.15.35.35.683.566 1.15.747.352.137.881.3 1.856.344 1.054.048 1.37.059 4.039.059 2.669 0 2.985-.01 4.039-.059.975-.044 1.504-.207 1.856-.344.467-.181.8-.398 1.15-.748.35-.35.566-.682.747-1.149.137-.352.3-.881.344-1.856.048-1.054.059-1.37.059-4.04 0-2.668-.01-2.984-.059-4.038-.044-.975-.207-1.504-.344-1.856a3.097 3.097 0 0 0-.748-1.15 3.097 3.097 0 0 0-1.149-.747c-.352-.137-.881-.3-1.856-.345-1.054-.048-1.37-.058-4.04-.058"
            />
          </g>
          <g>
            <path
              fill="#FFF"
              d="M6.67 10.009a3.34 3.34 0 1 1 6.678 0 3.34 3.34 0 0 1-6.679 0zm3.334-5.139a5.134 5.134 0 1 0 0 10.267 5.134 5.134 0 0 0 0-10.267z"
            />
          </g>
          <g>
            <path
              fill="#FFF"
              d="M16.54 4.66a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0"
            />
          </g>
        </g>
      </g>
    </svg>
  </Link>
)

export default InstagramIcon
