import React from 'react'

const OncoguideIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="339.281"
    height="60.63"
    viewBox="0 0 339.281 60.63"
  >
    <g id="onco" transform="translate(-214.529 -203.48)">
      <g id="Group_53" data-name="Group 53">
        <g id="Group_39" data-name="Group 39">
          <path
            id="Path_7"
            data-name="Path 7"
            style={{ fill: '#FFF' }}
            d="M283.5,246.07a19.544,19.544,0,0,1-7.5-7.38,21.927,21.927,0,0,1,0-21.3,19.608,19.608,0,0,1,7.5-7.35,21.8,21.8,0,0,1,20.8,0,19.393,19.393,0,0,1,7.47,7.35,22.138,22.138,0,0,1,0,21.3,19.332,19.332,0,0,1-7.47,7.38,21.8,21.8,0,0,1-20.8,0Zm17.52-4.94a12.668,12.668,0,0,0,4.91-5.23,18.337,18.337,0,0,0,0-15.72,12.51,12.51,0,0,0-4.91-5.17,15.1,15.1,0,0,0-14.3,0,12.571,12.571,0,0,0-4.91,5.17,18.337,18.337,0,0,0,0,15.72,12.668,12.668,0,0,0,4.91,5.23,14.883,14.883,0,0,0,14.3,0Z"
          />
          <path
            id="Path_8"
            data-name="Path 8"
            style={{ fill: '#FFF' }}
            d="M343.94,217.34a11.274,11.274,0,0,1,4.68,4.65,15.318,15.318,0,0,1,1.69,7.44v18.89h-6.57v-17.9c0-2.87-.72-5.06-2.15-6.6a7.652,7.652,0,0,0-5.87-2.3,7.749,7.749,0,0,0-5.9,2.3q-2.175,2.295-2.18,6.6v17.9h-6.63V216.3h6.63v3.66a10.922,10.922,0,0,1,4.16-3.08,13.247,13.247,0,0,1,5.38-1.1,14.32,14.32,0,0,1,6.77,1.57Z"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            style={{ fill: '#FFF' }}
            d="M358.52,223.58a14.254,14.254,0,0,1,5.55-5.78,17.769,17.769,0,0,1,17.76.73,13.587,13.587,0,0,1,5.14,7.87h-7.15a7.535,7.535,0,0,0-2.79-3.72,8.255,8.255,0,0,0-4.82-1.34,8.023,8.023,0,0,0-6.48,2.88q-2.415,2.88-2.41,8.05t2.41,8.08a7.988,7.988,0,0,0,6.48,2.91q5.76,0,7.61-5.06h7.15a14.323,14.323,0,0,1-5.23,7.76,15.42,15.42,0,0,1-9.53,2.88,15.962,15.962,0,0,1-8.14-2.06,14.458,14.458,0,0,1-5.55-5.81,19.79,19.79,0,0,1,0-17.38Z"
          />
          <path
            id="Path_10"
            data-name="Path 10"
            style={{ fill: '#FFF' }}
            d="M399.29,246.77a14.74,14.74,0,0,1-5.75-5.81,17.572,17.572,0,0,1-2.09-8.69,17.249,17.249,0,0,1,2.15-8.66,14.839,14.839,0,0,1,5.87-5.81,18.024,18.024,0,0,1,16.62,0,14.839,14.839,0,0,1,5.87,5.81,17.223,17.223,0,0,1,2.15,8.66,16.879,16.879,0,0,1-2.21,8.66,15.265,15.265,0,0,1-6.01,5.84,17.3,17.3,0,0,1-8.4,2.06,16.441,16.441,0,0,1-8.19-2.06Zm12.99-4.91a9.261,9.261,0,0,0,3.63-3.66,11.771,11.771,0,0,0,1.39-5.93,12.051,12.051,0,0,0-1.34-5.9,8.938,8.938,0,0,0-3.55-3.63,9.73,9.73,0,0,0-4.77-1.22,9.589,9.589,0,0,0-4.74,1.22,8.679,8.679,0,0,0-3.46,3.63,12.489,12.489,0,0,0-1.28,5.9q0,5.175,2.64,7.99a8.727,8.727,0,0,0,6.65,2.82,9.89,9.89,0,0,0,4.79-1.22Z"
          />
          <path
            id="Path_11"
            data-name="Path 11"
            style={{ fill: '#FFF' }}
            d="M450.17,217.25a12.636,12.636,0,0,1,4.5,3.69v-4.65h6.68v32.54a16.339,16.339,0,0,1-1.86,7.87,13.391,13.391,0,0,1-5.38,5.43,16.835,16.835,0,0,1-8.4,1.98,18.257,18.257,0,0,1-10.81-3.05,11.239,11.239,0,0,1-4.88-8.28h6.57a7.108,7.108,0,0,0,3.23,4.04,10.9,10.9,0,0,0,5.9,1.54,8.819,8.819,0,0,0,6.48-2.44q2.475-2.445,2.47-7.09v-5.35a13.746,13.746,0,0,1-11.1,5.35,14.345,14.345,0,0,1-7.64-2.12,14.847,14.847,0,0,1-5.46-5.93,18.192,18.192,0,0,1-2.01-8.63,17.663,17.663,0,0,1,2.01-8.54,14.592,14.592,0,0,1,13.1-7.84,14.2,14.2,0,0,1,6.6,1.48Zm3.14,9.27a9.557,9.557,0,0,0-3.57-3.72,9.372,9.372,0,0,0-4.77-1.28,9.573,9.573,0,0,0-4.77,1.25,9.388,9.388,0,0,0-3.57,3.66,11.448,11.448,0,0,0-1.37,5.72,11.8,11.8,0,0,0,1.37,5.81,9.674,9.674,0,0,0,3.6,3.81,9.259,9.259,0,0,0,4.74,1.31,9.35,9.35,0,0,0,8.34-5.03,11.762,11.762,0,0,0,1.37-5.78A11.564,11.564,0,0,0,453.31,226.52Z"
          />
          <path
            id="Path_12"
            data-name="Path 12"
            style={{ fill: '#FFF' }}
            d="M499.07,216.29v32.02h-6.63v-3.78a10.747,10.747,0,0,1-4.1,3.11,12.948,12.948,0,0,1-5.38,1.13,14.292,14.292,0,0,1-6.77-1.57,11.343,11.343,0,0,1-4.71-4.65,15.1,15.1,0,0,1-1.71-7.44V216.28h6.57v17.84q0,4.3,2.15,6.6a8.679,8.679,0,0,0,11.77,0q2.175-2.295,2.18-6.6V216.28h6.63Z"
          />
          <path
            id="Path_13"
            data-name="Path 13"
            style={{ fill: '#FFF' }}
            d="M508.14,210.83a4.24,4.24,0,0,1,3.02-7.26,4.038,4.038,0,0,1,2.96,1.22,4.348,4.348,0,0,1,0,6.04,4.038,4.038,0,0,1-2.96,1.22A4.109,4.109,0,0,1,508.14,210.83Zm6.28,5.46v32.02h-6.63V216.29Z"
          />
          <path
            id="Path_14"
            data-name="Path 14"
            style={{ fill: '#FFF' }}
            d="M522.93,223.61a14.593,14.593,0,0,1,13.1-7.84,13.968,13.968,0,0,1,6.6,1.48,13.376,13.376,0,0,1,4.5,3.69v-4.65h6.68v32.02h-6.68v-4.77a13.141,13.141,0,0,1-4.59,3.78,14.865,14.865,0,0,1-14.18-.61,14.92,14.92,0,0,1-5.43-5.93,18.192,18.192,0,0,1-2.01-8.63,17.663,17.663,0,0,1,2.01-8.54Zm22.84,2.91a9.556,9.556,0,0,0-3.57-3.72,9.372,9.372,0,0,0-4.77-1.28,9.573,9.573,0,0,0-4.77,1.25,9.388,9.388,0,0,0-3.57,3.66,11.448,11.448,0,0,0-1.37,5.72,11.826,11.826,0,0,0,1.37,5.81,9.674,9.674,0,0,0,3.6,3.81,9.259,9.259,0,0,0,4.74,1.31,9.35,9.35,0,0,0,8.34-5.03,11.763,11.763,0,0,0,1.37-5.78,11.565,11.565,0,0,0-1.37-5.75Z"
          />
        </g>
        <g id="Group_52" data-name="Group 52">
          <g id="Group_40" data-name="Group 40">
            <circle
              id="Ellipse_1"
              data-name="Ellipse 1"
              style={{ fill: '#D586B9' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(246.62 206.35)"
            />
            <path
              id="Path_15"
              data-name="Path 15"
              style={{ fill: '#D586B9' }}
              d="M247.8,216.84c1.02-1.76,1.4-3.45-.01-4.27s-2.68.36-3.7,2.12a23.521,23.521,0,0,0-1.86,6.23.711.711,0,0,0,.35.7.694.694,0,0,0,.78-.05,24.258,24.258,0,0,0,4.45-4.74Z"
            />
          </g>
          <g id="Group_41" data-name="Group 41">
            <circle
              id="Ellipse_2"
              data-name="Ellipse 2"
              style={{ fill: '#26B99F' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(225.22 243.4)"
            />
            <path
              id="Path_16"
              data-name="Path 16"
              style={{ fill: '#26B99F' }}
              d="M230.08,238.95c-1.02,1.76-1.4,3.45.01,4.27s2.68-.36,3.7-2.12a23.521,23.521,0,0,0,1.86-6.23.711.711,0,0,0-.35-.7.693.693,0,0,0-.78.05,24.258,24.258,0,0,0-4.45,4.74Z"
            />
          </g>
          <g id="Group_42" data-name="Group 42">
            <circle
              id="Ellipse_3"
              data-name="Ellipse 3"
              style={{ fill: '#E0558A' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(254.44 214.18)"
            />
            <path
              id="Path_17"
              data-name="Path 17"
              style={{ fill: '#E0558A' }}
              d="M252.14,222.75c1.76-1.02,2.94-2.29,2.12-3.7s-2.5-1.03-4.27-.01a24,24,0,0,0-4.73,4.47.708.708,0,0,0,.65,1.13,24.107,24.107,0,0,0,6.22-1.88Z"
            />
          </g>
          <g id="Group_43" data-name="Group 43">
            <circle
              id="Ellipse_4"
              data-name="Ellipse 4"
              style={{ fill: '#19A4D1' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(217.39 235.57)"
            />
            <path
              id="Path_18"
              data-name="Path 18"
              style={{ fill: '#19A4D1' }}
              d="M225.74,233.04c-1.76,1.02-2.94,2.29-2.12,3.7s2.5,1.03,4.27.01a24,24,0,0,0,4.73-4.47.708.708,0,0,0-.65-1.13,24.107,24.107,0,0,0-6.22,1.88Z"
            />
          </g>
          <g id="Group_44" data-name="Group 44">
            <circle
              id="Ellipse_5"
              data-name="Ellipse 5"
              style={{ fill: '#F47821' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(257.31 224.87)"
            />
            <path
              id="Path_19"
              data-name="Path 19"
              style={{ fill: '#F47821' }}
              d="M252.94,230.04c2.04,0,3.69-.52,3.69-2.14s-1.65-2.14-3.69-2.14a23.475,23.475,0,0,0-6.33,1.51.706.706,0,0,0,0,1.3,23.831,23.831,0,0,0,6.33,1.48Z"
            />
          </g>
          <g id="Group_45" data-name="Group 45">
            <circle
              id="Ellipse_6"
              data-name="Ellipse 6"
              style={{ fill: '#026EA0' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(214.53 224.87)"
            />
            <path
              id="Path_20"
              data-name="Path 20"
              style={{ fill: '#026EA0' }}
              d="M224.94,225.75c-2.04,0-3.69.52-3.69,2.14s1.65,2.14,3.69,2.14a23.475,23.475,0,0,0,6.33-1.51.706.706,0,0,0,0-1.3,23.831,23.831,0,0,0-6.33-1.48Z"
            />
          </g>
          <g id="Group_46" data-name="Group 46">
            <circle
              id="Ellipse_7"
              data-name="Ellipse 7"
              style={{ fill: '#FBAC18' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(254.44 235.57)"
            />
            <path
              id="Path_21"
              data-name="Path 21"
              style={{ fill: '#FBAC18' }}
              d="M249.99,236.75c1.76,1.02,3.45,1.4,4.27-.01s-.36-2.68-2.12-3.7a23.521,23.521,0,0,0-6.23-1.86.711.711,0,0,0-.7.35.693.693,0,0,0,.05.78,24.258,24.258,0,0,0,4.74,4.45Z"
            />
          </g>
          <g id="Group_47" data-name="Group 47">
            <circle
              id="Ellipse_8"
              data-name="Ellipse 8"
              style={{ fill: '#858887' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(217.39 214.18)"
            />
            <path
              id="Path_22"
              data-name="Path 22"
              style={{ fill: '#858887' }}
              d="M227.89,219.04c-1.76-1.02-3.45-1.4-4.27.01s.36,2.68,2.12,3.7a23.521,23.521,0,0,0,6.23,1.86.711.711,0,0,0,.7-.35.693.693,0,0,0-.05-.78,24.258,24.258,0,0,0-4.74-4.45Z"
            />
          </g>
          <g id="Group_48" data-name="Group 48">
            <circle
              id="Ellipse_9"
              data-name="Ellipse 9"
              style={{ fill: '#FFD24A' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(246.62 243.4)"
            />
            <path
              id="Path_23"
              data-name="Path 23"
              style={{ fill: '#FFD24A' }}
              d="M244.08,241.09c1.02,1.76,2.29,2.94,3.7,2.12s1.03-2.5.01-4.27a24,24,0,0,0-4.47-4.73.708.708,0,0,0-1.13.65,24.107,24.107,0,0,0,1.88,6.22Z"
            />
          </g>
          <g id="Group_49" data-name="Group 49">
            <circle
              id="Ellipse_10"
              data-name="Ellipse 10"
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(225.22 206.35)"
            />
            <path
              id="Path_24"
              data-name="Path 24"
              d="M233.8,214.7c-1.02-1.76-2.29-2.94-3.7-2.12s-1.03,2.5-.01,4.27a24,24,0,0,0,4.47,4.73.708.708,0,0,0,1.13-.65,24.107,24.107,0,0,0-1.88-6.22Z"
            />
          </g>
          <g id="Group_50" data-name="Group 50">
            <circle
              id="Ellipse_11"
              data-name="Ellipse 11"
              style={{ fill: '#30A649' }}
              cx="3.02"
              cy="3.02"
              r="3.02"
              transform="translate(235.92 246.27)"
            />
            <path
              id="Path_25"
              data-name="Path 25"
              style={{ fill: '#30A649' }}
              d="M236.79,241.89c0,2.04.52,3.69,2.14,3.69s2.14-1.65,2.14-3.69a23.475,23.475,0,0,0-1.51-6.33.706.706,0,0,0-1.3,0,23.831,23.831,0,0,0-1.48,6.33Z"
            />
          </g>
          <g id="Group_51" data-name="Group 51">
            <path
              id="Path_26"
              data-name="Path 26"
              style={{ fill: '#6951A1' }}
              d="M241.96,206.5a3.02,3.02,0,1,1-3.02-3.02A3.017,3.017,0,0,1,241.96,206.5Z"
            />
            <path
              id="Path_27"
              data-name="Path 27"
              style={{ fill: '#6951A1' }}
              d="M241.08,213.9c0-2.04-.52-3.69-2.14-3.69s-2.14,1.65-2.14,3.69a23.475,23.475,0,0,0,1.51,6.33.706.706,0,0,0,1.3,0,23.831,23.831,0,0,0,1.48-6.33Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default OncoguideIcon
