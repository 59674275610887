import React from 'react'
import { SuspenseSSR } from '@vtex/store-ui'

import { MyBagClient } from './MyBag.client'
import { MyBagFallback } from './MyBagFallback'

const MyBag = () => (
  <SuspenseSSR fallback={<MyBagFallback />}>
    <MyBagClient />
  </SuspenseSSR>
)

export default MyBag
