import React from 'react'
import type { FC } from 'react'

const UltramatteIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <g id="Ultramatte" transform="translate(0 0)">
      <g
        id="Rectangle_4262"
        data-name="Rectangle 4262"
        transform="translate(0 0)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      >
        <rect width="19" height="19" stroke="none" />
        <rect x="0.5" y="0.5" width="18" height="18" fill="none" />
      </g>
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="7.5"
        cy="7.5"
        r="7.5"
        transform="translate(2 2)"
        fill="#fff"
      />
    </g>
  </svg>
)

export default UltramatteIcon
