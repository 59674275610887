import type { FC } from 'react'
import React, { createContext, useState, useMemo } from 'react'

export interface IContext {
  isActive: boolean
  setIsActive: (value: boolean) => void
  isPurchaseTriggered: boolean
  setIsPurchaseTriggered: (value: boolean) => void
}

export const EventAddCartContext = createContext<IContext | undefined>(
  undefined
)

export const EventAddCartProvider: FC = ({ children }) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [isPurchaseTriggered, setIsPurchaseTriggered] = useState<boolean>(false)

  const value = useMemo(
    () => ({
      isActive,
      setIsActive: (val: boolean) => {
        setIsActive(val)
      },
      isPurchaseTriggered,
      setIsPurchaseTriggered: (val: boolean) => {
        setIsPurchaseTriggered(val)
      },
    }),
    [isActive, isPurchaseTriggered]
  )

  return (
    <EventAddCartContext.Provider value={value}>
      {children}
    </EventAddCartContext.Provider>
  )
}
