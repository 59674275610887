import { styled } from 'src/gatsby-theme-stitches/config'

export const NavigationBarWrapper = styled('div', {
  color: '$onBackground',
  py: '1.125rem',
  backgroundColor: '#00000005',

  '@sm': {
    display: 'none',
  },
})

export const NavigationBarContainer = styled('div', {
  width: '85%',
  maxWidth: '80rem',
  mx: 'auto',
  fontFamily: '$roboto',
  fontSize: '.875rem',

  a: {
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
  },
})

export const RepresentativeMenuContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',

  '.list, > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',

    li: {
      listStyle: 'none',
    },
  },

  'button, a': {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    outline: 'none',
    position: 'relative',
    transition: 'color .2s',

    '&:hover': {
      color: '$primary',

      i: {
        color: '$primary',
      },
    },
  },
})

export const ColabLogoutContainer = styled('div', {
  display: 'flex',
  gap: '1rem',
  fontFamily: '$roboto',

  a: {
    textDecoration: 'none',
    fontWeight: '$semiBold',
    transition: 'color 0.3s',

    '&:hover': {
      color: '$primary',
    },
  },

  button: {
    all: 'unset',
    cursor: 'pointer',
    fontFamily: 'inherit',
    textDecoration: 'underline',
    transition: 'color 0.3s',

    '&:hover': {
      color: '$primary',
    },
  },
})
