import React from 'react'

interface Props {
  color?: string
}

const WhatsappSvg = ({ color }: Props) => (
  <>
    <svg
      xmlns="http://www.w3.org/1999/xlink"
      width="32"
      height="37"
      viewBox="0 0 73.268 74.763"
    >
      <g
        id="Componente_16_1"
        data-name="Componente 16 – 1"
        transform="translate(2.698 2.5)"
        stroke={color ?? '#FFFFFF'}
        fill={color ?? '#FFFFFF'}
      >
        <path
          id="Caminho_23"
          data-name="Caminho 23"
          d="M34.035,0C52.832,0,68.07,15.566,68.07,34.767S52.832,69.534,34.035,69.534A33.38,33.38,0,0,1,17.02,64.865C12.58,66.011,8.6,66.789,1.1,68.71c1.83-5.581,2.989-8.021,4.767-14.4C1.281,47.393,0,42.133,0,34.767,0,15.566,15.238,0,34.035,0Z"
          fill="none"
          stroke={color ?? '#FFFFFF'}
          strokeLinecap="round"
          strokeWidth="5px"
        />
        <path
          id="Caminho_24"
          data-name="Caminho 24"
          d="M2481.692,937.225c.422-.423,3.622-3.32,2.414-5.613s-2.293-5.191-2.716-6.1a1.95,1.95,0,0,0-1.932-1.268c-.785.06-3.32-.121-4.406,1.268s-3.561,4.949-2.776,7.967a27.792,27.792,0,0,0,8.631,14.245c5.794,4.949,13.882,10.02,18.711,9.537s7.364-3.2,7.786-4.165,1.811-4.165,0-5.01-5.674-2.656-6.76-3.139-1.871-.543-2.6.543-1.69,2.294-2.173,2.958-1.449,1.086-2.777.3a31.913,31.913,0,0,1-7.364-5.07c-3.078-2.958-3.923-4.708-4.044-5.191S2481.269,937.648,2481.692,937.225Z"
          transform="translate(-2456.176 -904.554)"
          stroke={color ?? '#FFFFFF'}
          fill={color ?? '#FFFFFF'}
        />
      </g>
    </svg>
  </>
)

export default WhatsappSvg
