import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import { Icon, Logo } from '@naturacosmeticos/natds-react'
import useScrollPosition from 'src/hooks/useScrollPosition'
import { SuspenseSSR } from '@vtex/store-ui'
import { Link } from 'gatsby'

import { MyBagFallback } from '../MyBag/MyBagFallback'
import SearchBar from '../SearchBar'
import { IconsContainer, MenuMobileContainer } from './styled'

const MyBag = loadable(() => import('../MyBag'))
const SidebarMenu = loadable(() => import('../SidebarMenu'))

const MenuMobile = () => {
  const scrollPosition = useScrollPosition()
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [showSidebarMenu, setShowSidebarMenu] = useState(false)

  useEffect(() => {
    scrollPosition >= 65 ? setShowSearchBar(true) : setShowSearchBar(false)
  }, [scrollPosition])

  return (
    <>
      <MenuMobileContainer>
        <button onClick={() => setShowSidebarMenu(true)}>
          <Icon name="filled-navigation-menu" size="semi" />
        </button>

        {showSearchBar ? (
          <SearchBar />
        ) : (
          <Link to="/" className="logo">
            <Logo size="huge" color="highlight" />
          </Link>
        )}

        <IconsContainer>
          {!showSearchBar && (
            <button onClick={() => setShowSearchBar(true)}>
              <Icon name="outlined-action-search" size="standard" />
            </button>
          )}

          <SuspenseSSR fallback={<MyBagFallback />}>
            <MyBag />
          </SuspenseSSR>
        </IconsContainer>
      </MenuMobileContainer>

      <SuspenseSSR fallback={null}>
        <SidebarMenu
          showSidebarMenu={showSidebarMenu}
          setShowSidebarMenu={setShowSidebarMenu}
        />
      </SuspenseSSR>
    </>
  )
}

export default MenuMobile
