import React from 'react'
import type { FC } from 'react'

const EmailNonFilledIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="16"
    viewBox="0 0 19 13.063"
  >
    <g id="noun-email-4471805" transform="translate(0 -5)">
      <path
        id="Path_6730"
        data-name="Path 6730"
        d="M19,7.375A2.377,2.377,0,0,0,16.625,5H2.375A2.377,2.377,0,0,0,0,7.375v8.313c0,.047.011.091.014.138a.583.583,0,0,0,.03.3,2.376,2.376,0,0,0,2.331,1.939h14.25a2.376,2.376,0,0,0,2.331-1.939.583.583,0,0,0,.03-.3c0-.047.014-.091.014-.138Zm-4.915,3.879,3.727-2.236v5.566ZM2.375,6.188h14.25a1.189,1.189,0,0,1,1.187,1.188v.258L11.027,11.7a2.96,2.96,0,0,1-3.055,0L1.188,7.633V7.375A1.189,1.189,0,0,1,2.375,6.187ZM1.188,9.017l3.727,2.236-3.727,3.33Zm15.438,7.858H2.375a1.187,1.187,0,0,1-1.108-.77l4.714-4.211,1.38.828a4.148,4.148,0,0,0,4.277,0l1.38-.828L17.733,16.1a1.187,1.187,0,0,1-1.108.77Z"
      />
    </g>
  </svg>
)

export default EmailNonFilledIcon
