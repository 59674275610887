import { styled } from 'src/gatsby-theme-stitches/config'

export const MinicartOverlay = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '$mediumEmphasis',
  opacity: '50%',
})

export const MinicartContainer = styled('div', {
  position: 'fixed',
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: '$white',
  width: '33vw',
  maxWidth: '38rem',
  fontFamily: '$roboto',
  fontWeight: '$medium',
  color: '$mediumEmphasis',
  display: 'flex',
  flexDirection: 'column',
})

export const MinicartHeader = styled('header', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid $colors$lowEmphasis',
  position: 'relative',
  py: '1.5rem',

  p: {
    color: '$highlight',
  },

  button: {
    all: 'unset',
    position: 'absolute',
    right: '21px',
    cursor: 'pointer',
  },
})

export const MinicartProducts = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '52.5vh',
  overflow: 'auto',

  '&::-webkit-scrollbar': {
    width: '0.5rem',

    '&-track': {
      backgroundColor: '$lowEmphasis',
    },

    '&-thumb': {
      backgroundColor: '$mediumEmphasis',

      '&:hover': {
        backgroundColor: '$highEmphasis',
      },
    },
  },
})

export const ProductContainer = styled('div', {
  padding: '1.5rem 1rem',
  borderBottom: '1px solid $colors$lowEmphasis',
  display: 'flex',
  gap: '1rem',

  '.product-info': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: '.5rem',

    a: {
      textDecoration: 'none',
      color: '$mediumEmphasis',
      transition: 'color 0.2s',

      '&:hover': {
        color: '$highEmphasis',
      },
    },

    '> div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '.5rem',

      '> button': {
        all: 'unset',
        cursor: 'pointer',
        height: '1.5rem',
      },
    },

    '.product-name': {
      fontSize: '.875rem',
    },

    '.price-info': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',

      span: {
        fontWeight: '$regular',
        fontSize: '1rem',
        textDecoration: 'line-through',
      },

      p: {
        fontSize: '1.25rem',
        color: '$primary',
      },
    },
  },
})

export const MinicartFooter = styled('footer', {
  display: 'flex',
  flexDirection: 'column',
  borderTop: '1px solid $colors$lowEmphasis',
  fontWeight: '$regular',
  marginTop: 'auto',

  '.price-container': {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem 2rem',
    gap: '.75rem',

    '> div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '1rem',
      fontSize: '1rem',
      color: '$mediumEmphasis',

      '&.discount': {
        color: '$success',
      },

      '&.total': {
        color: '$highlight',
      },
    },
  },

  button: {
    borderRadius: '0',
  },
})
