import React from 'react'
import type { FC } from 'react'
import { Link } from '@vtex/store-ui'

const YoutubeIcon: FC = () => (
  <Link
    href="https://www.youtube.com/user/AvonBR"
    target="_blank"
    aria-label="Youtube Icon"
    title="Youtube Icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="15"
      viewBox="0 0 21 15"
    >
      <g>
        <g>
          <path
            fill="#FFF"
            d="M13.689 7.729l-5.742 2.738a.23.23 0 0 1-.33-.208V4.611a.23.23 0 0 1 .335-.206l5.742 2.91a.23.23 0 0 1-.005.414zM16.639 0H4.36A4.361 4.361 0 0 0 0 4.361V10.5a4.361 4.361 0 0 0 4.361 4.361H16.64A4.361 4.361 0 0 0 21 10.5V4.36A4.361 4.361 0 0 0 16.639 0z"
          />
        </g>
      </g>
    </svg>
  </Link>
)

export default YoutubeIcon
