import { useContext } from 'react'

import { PreHeaderContext } from './index'

export const usePreHeader = () => {
  const context = useContext(PreHeaderContext)

  if (context === undefined) {
    throw new Error('Region context provider missing from React tree')
  }

  return context
}
