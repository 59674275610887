import { styled } from 'src/gatsby-theme-stitches/config'

export const UserButtonContainer = styled('div', {
  position: 'relative',

  li: {
    listStyle: 'none',
  },
})

export const UserMenuContainer = styled('div', {
  position: 'absolute',
  boxShadow: '0px 3px 6px #00000029',
  backgroundColor: '$onPrimary',
  display: 'flex',
  flexDirection: 'column',

  'button, a': {
    padding: '1.125rem 1rem',
    backgroundColor: '$onPrimary',
    color: '$onBackground',
    fontSize: '.75rem',
    width: '12rem',
    textDecoration: 'none',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#0000000F',
    },
  },
})

export const UserButton = styled('button', {
  all: 'unset',
  display: 'flex',
  gap: '0.75rem',
  fontSize: '.875rem',
  color: '$mediumEmphasis',
  cursor: 'pointer',
  alignItems: 'center',

  p: {
    fontWeight: '$medium',
  },

  '.arrow': {
    transition: 'transform 0.2s',
  },

  '&:hover': {
    '.arrow': {
      transform: 'rotate(180deg)',
    },
  },
})
