import React from 'react'
import type { FC } from 'react'

const OutlinedHeartIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
    width="60px"
  >
    <g transform="translate(0,-952.36218)">
      <path
        sx={{
          textIndent: '0',
          textTransform: 'none',
          direction: 'ltr',
          msBlockProgression: 'tb',
          baselineShift: 'baseline',
          color: '#000',
        }}
        d="m 32.34375,966.26843 c -6.66881,0 -12.30749,3.07896 -16.21875,7.8125 -3.91126,4.73354 -6.125,11.11073 -6.125,17.78125 0,11.31082 4.45063,19.43622 11.59375,26.37492 7.14312,6.9388 16.95392,12.7608 27.875,19.8438 l 0.53125,0.375 0.53125,-0.375 c 10.92109,-7.083 20.73188,-12.905 27.875,-19.8438 C 85.54937,1011.2984 90,1003.173 90,991.86218 c 0,-6.67052 -2.21374,-13.04771 -6.125,-17.78125 -3.91126,-4.73354 -9.54994,-7.8125 -16.21875,-7.8125 -8.03875,0 -14.47541,4.10944 -17.65625,7.0625 -3.18084,-2.95306 -9.61749,-7.0625 -17.65625,-7.0625 z m 0,2 c 7.81531,0 14.40538,4.49109 16.9375,7.0625 L 50,976.04968 l 0.71875,-0.71875 c 2.53212,-2.57141 9.1222,-7.0625 16.9375,-7.0625 6.05584,0 11.0701,2.75367 14.65625,7.09375 3.58615,4.34008 5.6875,10.27578 5.6875,16.5 0,10.79442 -4.11263,18.24712 -11,24.93742 -6.76794,6.5744 -16.27609,12.3416 -27,19.2813 -10.72391,-6.9397 -20.23206,-12.7069 -27,-19.2813 -6.88737,-6.6903 -11,-14.143 -11,-24.93742 0,-6.22422 2.10135,-12.15992 5.6875,-16.5 3.58615,-4.34008 8.60041,-7.09375 14.65625,-7.09375 z"
        fill="#000000"
        fillOpacity="1"
        stroke="none"
        marker="none"
        visibility="visible"
        display="inline"
        overflow="visible"
      />
    </g>
  </svg>
)

export default OutlinedHeartIcon
