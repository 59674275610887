import { Icon } from '@naturacosmeticos/natds-react'
import { useOrderItems } from '@vtex/gatsby-theme-store'
import { NumericStepper } from '@vtex/store-ui'
import React, { useCallback } from 'react'
import { EventRemoveFromCart } from 'src/components/common/EventRemoveFromCart'
import type { OrderFormProduct } from 'src/typings'
import { formatPrice } from 'src/utils/formatPrice'

import { ProductContainer } from './styled'

interface Props {
  product: OrderFormProduct
}

const MinicartProduct = ({ product }: Props) => {
  const { removeItem, updateQuantity } = useOrderItems()

  const isDisabled = product.price === 0 || product.availability !== 'available'

  const hasDiscount = product.sellingPrice < product.listPrice
  const price = formatPrice(product.sellingPrice / 100)
  const listPrice = formatPrice(product.listPrice / 100)

  const handleChangeQuantity = useCallback(
    (quantity: number) => {
      if (quantity === product.quantity) {
        return null
      }

      return updateQuantity({ ...product, quantity }, product)
    },
    [product, updateQuantity]
  )

  const handleRemoveProduct = () => {
    EventRemoveFromCart({
      brand: product.additionalInfo.brandName,
      category: product.productCategories,
      id: product.id,
      name: product.name,
      price,
      quantity: product.quantity,
    })

    return removeItem(product)
  }

  return (
    <ProductContainer>
      <a href={product.detailUrl}>
        <img
          src={product.imageUrls?.at2x}
          alt={String(product.name)}
          width="92px"
          height="92px"
        />
      </a>

      <div className="product-info">
        <div>
          <a href={product.detailUrl}>
            <p className="product-name">{product.name}</p>
          </a>

          <button onClick={handleRemoveProduct}>
            <Icon name="outlined-action-delete" />
          </button>
        </div>

        <div>
          <NumericStepper
            onChange={handleChangeQuantity}
            disabled={isDisabled}
            value={product.quantity}
            max={10}
            variant="productQuantityDesignSystem"
          />

          <div className="price-info">
            {hasDiscount && <span>{listPrice}</span>}
            <p>{price}</p>
          </div>
        </div>
      </div>
    </ProductContainer>
  )
}

export default MinicartProduct
