import React from 'react'
import type { FC } from 'react'
import { Link } from '@vtex/store-ui'

const InfotechnologyIcon: FC = () => (
  <Link
    href="https://infotechnology.com.br/"
    target="_blank"
    aria-label="Infotechnology Icon"
    title="Infotechnology Icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 1086.4 401.71"
      fill="#FFF"
      width="100"
    >
      <path
        className="cls-1"
        d="M85.1,308.84H62.34V392.9H34.87V308.84H12.5V285.57H85.1Z"
      />
      <path
        className="cls-1"
        d="M261,390.39q-13.47,5.43-24.92,5.43a54.81,54.81,0,0,1-35.76-13q-20.12-16.59-20.12-43.25t20.12-43.33a55.12,55.12,0,0,1,36-13q11,0,24.69,5.43v33.42a31.28,31.28,0,0,0-7.91-7.28,29.76,29.76,0,0,0-15.81-4.51,28.27,28.27,0,0,0-18.61,6.54q-10,8.28-10,22.71t10,22.63a28.27,28.27,0,0,0,18.61,6.54,29.76,29.76,0,0,0,15.81-4.51A31.11,31.11,0,0,0,261,357Z"
      />
      <path
        className="cls-1"
        d="M373,392.9H345.55V348.24H305.92V392.9H278.44V285.57h27.48V327h39.63V285.57H373Z"
      />
      <path
        className="cls-1"
        d="M500.74,392.9H473.26l-50.54-65.74V392.9H395.24V285.57h27.48l50.54,65.66V285.57h27.48Z"
      />
      <path
        className="cls-1"
        d="M635.11,339.59A56.79,56.79,0,0,1,620.79,378q-16.34,18.79-44.2,18.79T532.47,378a56.79,56.79,0,0,1-14.32-38.37A59,59,0,0,1,522,319.1a57.09,57.09,0,0,1,10.37-18q16.33-18.79,44.2-18.79t44.2,18.79A56.89,56.89,0,0,1,635.11,339.59Zm-28.48,0a30,30,0,0,0-47.18-24.8,29.43,29.43,0,0,0-10.94,14.39,30.24,30.24,0,0,0-1.86,10.41,29.64,29.64,0,0,0,7.14,19.47,28.3,28.3,0,0,0,22.82,10.64,28.62,28.62,0,0,0,22.89-10.56A29.38,29.38,0,0,0,606.62,339.59Z"
      />
      <path className="cls-1" d="M713,392.9H652.52V285.57H680v84.06h33Z" />
      <path
        className="cls-1"
        d="M839.29,339.59A56.79,56.79,0,0,1,825,378q-16.34,18.79-44.2,18.79T736.66,378a56.79,56.79,0,0,1-14.32-38.37,59,59,0,0,1,3.87-20.49,57.09,57.09,0,0,1,10.37-18q16.33-18.79,44.2-18.79T825,301.13A56.89,56.89,0,0,1,839.29,339.59Zm-28.48,0a30,30,0,0,0-47.18-24.8,29.43,29.43,0,0,0-10.94,14.39,30.24,30.24,0,0,0-1.86,10.41A29.64,29.64,0,0,0,758,359.06a28.3,28.3,0,0,0,22.82,10.64,28.62,28.62,0,0,0,22.89-10.56A29.38,29.38,0,0,0,810.81,339.59Z"
      />
      <path
        className="cls-1"
        d="M1072.26,285.57l-39.55,55.75V392.9h-27.48V341.32L967,285.57h32.82l20,29.25L1039,285.57Z"
      />
      <path
        className="cls-1"
        d="M105.79,285.22h59.95v23.51H105.79Zm0,65h50.76V326.67H105.79Zm0,42.46h59.95V369.13H105.79Z"
      />
      <path
        className="cls-1"
        d="M939.29,387.56q13.54-9,19.43-25.79,3.87-11,3.87-28.47H911.35v21.23H930.7q-1.24,8-5.28,12.06-6.13,6.18-16.61,6.18-11.8,0-18.87-7.61-9.55-10.2-9.55-25.5A39.59,39.59,0,0,1,883,325.39q7.06-18.75,25-18.75A22.83,22.83,0,0,1,923.17,312a25.55,25.55,0,0,1,5,6.07L954,306.79a50.05,50.05,0,0,0-12.89-14.93q-13-9.75-31.73-9.75-28,0-43.65,18.17-13.78,16-13.78,39.4,0,21.16,11.77,36.41,15.56,20.13,44.66,20.13Q926.21,396.21,939.29,387.56Z"
      />
      <path
        className="cls-1"
        d="M542.73,13h-510c-15.86,0-20.9,5.12-20.9,21.21q0,93,0,186c0,15,5.34,20.45,20.08,20.45h512.4c14.77,0,20.11-5.43,20.11-20.42q0-93,0-186C564.38,17.86,559.42,13,542.73,13ZM86,194.23H50.4V46.12H86Zm165.65,0H216l-65.55-90.72v90.72H114.85V46.12h35.64L216,136.72V46.12h35.64Zm107.22-116H316v25.28h39.05v32.12H316v58.59H280.4V46.12H358.9Zm148.28,95.38Q486,199.55,449.86,199.55t-57.22-25.93q-18.58-22.79-18.57-53a86.1,86.1,0,0,1,5-28.27A80.24,80.24,0,0,1,392.54,67.6q21.18-25.93,57.32-25.93T507.18,67.6q18.57,22.79,18.57,53.06T507.18,173.62Z"
      />
      <path
        className="cls-1"
        d="M449.86,79a36.25,36.25,0,0,0-22.24,7.45,40.43,40.43,0,0,0-14.19,19.85A44.16,44.16,0,0,0,411,120.67a42.5,42.5,0,0,0,9.26,26.87q11.47,14.69,29.59,14.69t29.69-14.57a42.1,42.1,0,0,0,9.26-27q0-15.67-9.26-27.09Q467.67,79,449.86,79Z"
      />
    </svg>
  </Link>
)

export default InfotechnologyIcon
