import { useOrderItems } from '@vtex/gatsby-theme-store'
import { useSession } from '@vtex/store-sdk'
import { getCookie, deleteCookie, setCookie } from 'src/utils/cookies'
import useStoreType from 'src/contexts/StoreTypeContext/useStoreType'

export const useColabStore = () => {
  const { setSession, channel, ...session } = useSession()
  const { addItems } = useOrderItems()
  const { isCollaboratorStore, setIsCollaboratorStore } = useStoreType()

  const setColabStore = () => {
    setSession({
      channel: '3',
      ...session,
    })

    addItems([{ id: '0', quantity: 1, seller: '1' }], {
      salesChannel: '3',
    })

    setIsCollaboratorStore(true)
    setCookie('isUserColab', 'true')
    setCookie('isColaborador', 'true')

    window.location.href = '/'
  }

  const setNormalStore = () => {
    setSession({
      channel: '1',
      ...session,
    })

    addItems([{ id: '0', quantity: 1, seller: '1' }], {
      salesChannel: '1',
    })

    setIsCollaboratorStore(false)
    deleteCookie('isUserColab')
    deleteCookie('isColaborador')

    window.location.href = '/'
  }

  const colabCookie = getCookie('isUserColab')

  colabCookie ? setIsCollaboratorStore(true) : setIsCollaboratorStore(false)

  const isUserColab = isCollaboratorStore ?? channel === '3'

  return {
    isUserColab,
    setColabStore,
    setNormalStore,
  }
}
