import React from 'react'
import type { FC } from 'react'

const DoubleArrow: FC = () => (
  <svg width="10" height="10" viewBox="0 0 20 20">
    <path d="M5.4 21L4.1 19.5 11.7 12 4.1 4.5 5.5 3 14.6 12z" />
    <path d="M11.5 20.9L10 19.6 17.4 12 9.9 4.5 11.3 3 20.2 12z" />
  </svg>
)

export default DoubleArrow
