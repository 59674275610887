import { styled } from 'src/gatsby-theme-stitches/config'

export const MyBagContainer = styled('div', {
  display: 'flex',
  position: 'relative',
  cursor: 'pointer',

  span: {
    position: 'absolute',
    top: '-2px',
    right: '-6px',
    borderRadius: '100px',
    backgroundColor: '$alert',
    color: '$white',
    fontSize: '.75rem',
    height: 'auto',
    width: '1.125rem',
    display: 'grid',
    placeItems: 'center',
    letterSpacing: '-1px',
  },
})
