import { Link } from '@vtex/store-ui'
import React from 'react'

const EnextIcon = () => {
  return (
    <Link href="https://enext.com.br/">
      <img
        src="https://avonqa.vteximg.com.br/arquivos/logoEnext.png"
        alt="Ícone Enext"
        height="20px"
        width="65px"
        loading="lazy"
      />
    </Link>
  )
}

export default EnextIcon
