interface Props {
  id: string
  name: string
  price: string
  quantity: number
  category: {
    [categoryId: number]: string
  }
  brand: string
}
export const EventRemoveFromCart = ({
  brand,
  category,
  id,
  name,
  price,
  quantity,
}: Props) => {
  if (!id) {
    return null
  }

  window.dataLayer = window.dataLayer || []

  const { dataLayer } = window

  return dataLayer.push({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            id,
            name,
            price,
            quantity,
            category,
            brand,
          },
        ],
      },
    },
  })
}
