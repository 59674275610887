import React from 'react'
import type { FC } from 'react'
import { Link } from '@vtex/store-ui'

const PinterestIcon: FC = () => (
  <Link
    href="https://br.pinterest.com/avonbr/"
    target="_blank"
    aria-label="Pinterest Icon"
    title="Pinterest Icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g>
        <g>
          <path
            fill="#FFF"
            d="M10 0C4.477 0 0 4.477 0 10c0 4.236 2.636 7.858 6.356 9.315-.088-.791-.167-2.008.035-2.871.181-.781 1.172-4.971 1.172-4.971s-.3-.6-.3-1.485c0-1.39.807-2.429 1.81-2.429.853 0 1.266.641 1.266 1.409 0 .858-.547 2.14-.829 3.33-.235.995.5 1.807 1.481 1.807 1.778 0 3.144-1.874 3.144-4.58 0-2.394-1.72-4.068-4.177-4.068-2.845 0-4.516 2.134-4.516 4.34 0 .859.332 1.78.744 2.282a.3.3 0 0 1 .07.287c-.076.315-.245.995-.278 1.133-.044.184-.144.222-.334.135-1.25-.582-2.03-2.408-2.03-3.875 0-3.154 2.292-6.051 6.607-6.051 3.469 0 6.165 2.472 6.165 5.776 0 3.446-2.173 6.22-5.189 6.22-1.013 0-1.966-.527-2.292-1.149l-.623 2.377c-.226.869-.836 1.958-1.243 2.622.936.29 1.93.446 2.961.446 5.523 0 10-4.477 10-10S15.523 0 10 0"
          />
        </g>
      </g>
    </svg>
  </Link>
)

export default PinterestIcon
