import React from 'react'
import { SuspenseSSR } from '@vtex/store-ui'

import { UserFallback } from './UserFallback'
import { UserClient } from './User.client'

const User = () => (
  <SuspenseSSR fallback={<UserFallback />}>
    <UserClient />
  </SuspenseSSR>
)

export default User
