import { styled } from 'src/gatsby-theme-stitches/config'

export const MenuContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '85%',
  maxWidth: '80rem',
  mx: 'auto',
  py: '.75rem',

  '@sm': {
    display: 'none',
  },
})

export const ItemWrapper = styled('div', {
  '&:hover': {
    '> a': {
      color: '$primary',

      '&:before': {
        opacity: '1',
        bottom: '-14px',
      },
    },
  },

  '> a': {
    textDecoration: 'none',
    fontSize: '.875rem',
    lineHeight: '1.3125rem',
    position: 'relative',
    color: '$$color',
    transition: 'color 0.2s',
    fontFamily: '$roboto',

    '&:before': {
      content: '',
      position: 'absolute',
      bottom: '-16px',
      left: '0',
      right: '0',
      height: '2px',
      backgroundColor: '$primary',
      opacity: '0',
      transition: 'all 0.2s',
    },
  },

  variants: {
    showSubmenu: {
      true: {
        '.submenu': {
          visibility: 'visible',
          opacity: '1',
        },
      },
    },
  },
})

export const SubmenuContainer = styled('div', {
  position: 'absolute',
  zIndex: '10',
  backgroundColor: '$white',
  left: '0',
  right: '0',
  top: '220px',
  opacity: '0',
  visibility: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.2s',
  fontSize: '.875rem',
  paddingTop: '1.375rem',
  gap: '1.375rem',
  boxShadow: '0px 3px 6px #00000029',

  nav: {
    maxWidth: '80rem',
    width: '85%',
    mx: 'auto',
    display: 'flex',
    gap: '1rem',
  },

  a: {
    color: '#777',
    textDecoration: 'none',
    fontFamily: '$roboto',

    '&:hover': {
      color: '$primary',
    },
  },

  variants: {
    isUserColab: {
      true: {
        top: '177px',
      },
    },
  },
})

export const SubmenuItem = styled('div', {
  width: '10rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',

  '> a': {
    fontWeight: '$medium',
    color: '$onBackground',
  },

  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
})

export const ExtraLinksContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
})

export const SeeAllContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  py: '1rem',

  a: {
    color: '$primary',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
})
