import React, { useEffect } from 'react'
import type { FC } from 'react'
import 'keen-slider/keen-slider.min.css'
import { ErrorBoundary } from 'react-error-boundary'
import { SuspenseSSR } from '@vtex/store-ui'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useGlobalUIState } from '@vtex/store-sdk'
import { useLocation } from '@reach/router'
import {
  sendPixelEvent,
  useOrderForm,
  useOrderItems,
} from '@vtex/gatsby-theme-store'
import useStoreType from 'src/contexts/StoreTypeContext/useStoreType'
import { PreHeaderProvider } from 'src/contexts/PreHeaderContext'
import useRepresentative from 'src/contexts/RepresentativeContext/useRepresentative'
import { EventAddCartProvider } from 'src/contexts/EventAddCartContext'
import loadable from '@loadable/component'
import type { CartPixelProduct } from '@vtex/gatsby-theme-store'
import type { AddItemItemParam } from '@vtex/order-items/types/modules/OrderItemsContext'
import Header from 'src/components/common/Header'
import Footer from 'src/components/common/Footer'
import useDeviceDetect from 'src/hooks/useDeviceDetect'
import { ThemeProvider } from '@naturacosmeticos/natds-react'
import { theme } from 'src/themes'
import { globalStyles } from 'src/gatsby-theme-stitches/config'

import ErrorFallback from '../ui/ErrorFallback'

import '@naturacosmeticos/natds-icons/dist/natds-icons.css'

const OfferOfTheDay = loadable(
  () => import('src/components/common/OfferOfTheDay')
)

const RepresentativeComponents = loadable(
  () => import('src/components/common/RepresentativeComponents')
)

const LoginModal = loadable(() => import('src/components/auth/LoginModal'))

const NotifyMeModal = loadable(
  () => import('src/components/design-system/common/NotifyMeModal')
)

const LazyToast = loadable(() => import('src/components/ui/Toast'))

const Newsletter = loadable(() => import('src/components/common/Newsletter'))

const GoToTopButton = loadable(
  () => import('src/components/common/GoToTopButton')
)

declare global {
  interface Window {
    customAddToCart: unknown
  }
}

interface Props {
  hideHeaderAndFooter?: boolean
}

const Layout: FC<Props> = ({ children, hideHeaderAndFooter }) => {
  const { isCollaboratorStore } = useStoreType()
  const { addItems } = useOrderItems()
  const { openMinicart } = useGlobalUIState()
  const { orderForm } = useOrderForm()
  const { isMobile } = useDeviceDetect()
  const { isRepresentativeSelected } = useRepresentative()
  const { pathname } = useLocation()

  globalStyles()

  useEffect(() => {
    window.customAddToCart = (addItemsObject: { items: IItems[] }) => {
      const { items } = addItemsObject

      const salesChannel = isCollaboratorStore
        ? '3'
        : isRepresentativeSelected
        ? '2'
        : '1'

      const orderFormItems: AddItemItemParam[] = []

      const pixelEventsProducts: CartPixelProduct[] =
        [] as unknown as CartPixelProduct[]

      const customDataLayerProducts: ICustomDataLayerProducts[] = []

      const currentOrderFormItems = orderForm.items

      items.forEach((item) => {
        const sameOrderFormItems = currentOrderFormItems.filter(
          (currentItem) => {
            return (
              currentItem.id.toString() === item.itemId.toString() &&
              currentItem.sellingPrice !== 0
            )
          }
        )

        let quantity = item?.quantity || 1

        if (sameOrderFormItems.length) {
          sameOrderFormItems.forEach(
            (sameOrderFormItem: { quantity: number }) => {
              quantity += sameOrderFormItem.quantity
            }
          )
        }

        orderFormItems.push({
          id: item.itemId,
          price: item?.price * 100,
          sellingPrice: item?.sellingPrice * 100,
          imageUrl: item?.imageUrl,
          name: item?.productName,
          quantity,
          seller: item.seller ?? 1,
        })

        pixelEventsProducts.push({
          productId: item?.productId,
          productReferenceId: item?.productReferenceId,
          productName: item?.productName,
          brand: item?.brand,
          categoryTree: item?.categoryTree,
          price: item?.sellingPrice,
          quantity: item?.quantity,
          skuId: item?.itemId,
          skuName: item?.name,
          skuReferenceId: item?.referenceId,
        } as CartPixelProduct)

        customDataLayerProducts.push({
          name: item?.productName,
          id: item?.productId,
          price: item?.sellingPrice,
          quantity: item?.quantity,
          category: item?.categoryTree
            ? item?.categoryTree[item?.categoryTree.length - 1]
            : '',
          brand: item?.brand,
          variant: item?.itemId,
        })
      })

      addItems(orderFormItems, {
        allowedOutdatedData: ['paymentData'],
        salesChannel,
      })

      if (!isMobile) {
        openMinicart()
      }

      window.dataLayer = window.dataLayer || []

      const { dataLayer } = window

      dataLayer.push({
        event: 'addCart',
        ecommerce: {
          add: {
            products: customDataLayerProducts,
          },
        },
      })

      sendPixelEvent({
        type: 'vtex:addToCart',
        data: {
          products: pixelEventsProducts,
        },
      })
    }
  }, [
    isCollaboratorStore,
    isRepresentativeSelected,
    addItems,
    openMinicart,
    orderForm,
    isMobile,
  ])

  return (
    <ThemeProvider theme={theme} cssPrefix="avon">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <PreHeaderProvider>
          <GatsbySeo />
          <EventAddCartProvider>
            {!hideHeaderAndFooter && <Header />}

            <main>{children}</main>

            {!hideHeaderAndFooter && (
              <>
                {children &&
                  pathname !== '/institucional/seja-representante/cadastro' && (
                    <Newsletter />
                  )}
                <Footer />
                <GoToTopButton />
              </>
            )}

            <RepresentativeComponents />

            <SuspenseSSR fallback={null}>
              <OfferOfTheDay />
              <LoginModal />
              <LazyToast />
              <NotifyMeModal />
            </SuspenseSSR>
          </EventAddCartProvider>
        </PreHeaderProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default Layout
