import React from 'react'
import type { FC } from 'react'

const CloseIcon: FC = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 17.4 17.3"
    xmlSpace="preserve"
    name="Fechar"
  >
    <path d="M0.7,16c0-0.2,0.1-0.4,0.2-0.5L15.5,0.9c0.3-0.3,0.7-0.3,1,0s0.3,0.7,0,1L1.9,16.5c-0.3,0.3-0.7,0.3-1,0C0.8,16.4,0.7,16.2,0.7,16z" />
    <path d="M7.6,6.6L1.9,0.9c-0.3-0.3-0.7-0.3-1,0C0.8,1,0.7,1.2,0.7,1.4s0.1,0.4,0.2,0.5l5.7,5.7L7.6,6.6z" />
    <path d="M9.8,10.8l5.7,5.7c0.3,0.3,0.7,0.3,1,0s0.3-0.7,0-1l-5.7-5.7L9.8,10.8z" />
  </svg>
)

export default CloseIcon
