import type { FC } from 'react'
import React from 'react'

const OnlineStoreSvg: FC = () => (
  <>
    <svg
      xmlns="http://www.w3.org/1999/xlink"
      width="32"
      height="37"
      viewBox="0 0 70.263 71.667"
    >
      <g
        id="Componente_18_1"
        data-name="Componente 18 – 1"
        transform="translate(2.5 2.5)"
        stroke="#FFFFFF"
        fill="#FFFFFF"
      >
        <path
          id="Caminho_22"
          data-name="Caminho 22"
          d="M32.632,0C50.654,0,65.263,14.924,65.263,33.333S50.654,66.667,32.632,66.667,0,51.743,0,33.333,14.61,0,32.632,0Z"
          fill="none"
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5px"
        />
        <path
          id="Caminho_18"
          data-name="Caminho 18"
          d="M87.5,543.012v64.755"
          transform="translate(-54.906 -542.17)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="5px"
        />
        <path
          id="Caminho_19"
          data-name="Caminho 19"
          d="M91.413,543c7.612,8.413,22.94,41.218.906,64.054"
          transform="translate(-51.78 -541.453)"
          fill="none"
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeWidth="5px"
        />
        <path
          id="Caminho_20"
          data-name="Caminho 20"
          d="M102.454,543.3c-7.893,7.134-20.924,41.11,1.214,64.054"
          transform="translate(-78.262 -541.755)"
          fill="none"
          stroke="#FFFFFF"
          strokeLinecap="round"
          strokeWidth="5px"
        />
        <path
          id="Caminho_21"
          data-name="Caminho 21"
          d="M70.9,559.112h64.724"
          transform="translate(-69.659 -525.434)"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="5px"
        />
      </g>
    </svg>
  </>
)

export default OnlineStoreSvg
