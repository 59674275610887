import React from 'react'

import { GoogleCertificate } from '../common/Footer/styled'

const GoogleFooterIcon = () => {
  return (
    <GoogleCertificate>
      <img
        src="https://avongroup.vteximg.com.br/arquivos/enext-certified-google.png"
        alt="Certificado Google"
        height="24px"
        width="174px"
        loading="lazy"
      />
    </GoogleCertificate>
  )
}

export default GoogleFooterIcon
