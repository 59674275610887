import type { FC } from 'react'
import React from 'react'

const SearchIcon: FC = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 13.29 14.211"
    >
      <path
        id="Caminho_6"
        data-name="Caminho 6"
        d="M12.5,11.938h-.6l-.213-.219a5.476,5.476,0,0,0,1.193-3.437A5.119,5.119,0,0,0,7.939,3,5.119,5.119,0,0,0,3,8.281a5.119,5.119,0,0,0,4.939,5.281,4.735,4.735,0,0,0,3.214-1.276l.205.228v.642l3.8,4.054L16.29,16Zm-4.559,0A3.539,3.539,0,0,1,4.52,8.281,3.539,3.539,0,0,1,7.939,4.625a3.539,3.539,0,0,1,3.419,3.656A3.539,3.539,0,0,1,7.939,11.938Z"
        transform="translate(-3 -3)"
        fill="#404040"
      />
    </svg>
  </>
)

export default SearchIcon
