import React from 'react'
import { SuspenseViewport } from '@vtex/store-ui'
import {
  AmericanExpressIcon,
  DinersIcon,
  EloIcon,
  HipercardIcon,
  MastercardIcon,
  NewBoletoIcon,
  PixIcon,
  VisaIcon,
} from 'src/components/icons'

import { PaymentIcons, PaymentIconsContainer } from './styled'

const PaymentMethods = () => {
  return (
    <PaymentIconsContainer>
      <p>Formas de pagamento</p>

      <SuspenseViewport fallback={null}>
        <PaymentIcons>
          <VisaIcon />
          <MastercardIcon />
          <AmericanExpressIcon />
          <DinersIcon />
          <HipercardIcon />
          <EloIcon />
          <NewBoletoIcon />
          <PixIcon />
        </PaymentIcons>
      </SuspenseViewport>
    </PaymentIconsContainer>
  )
}

export default PaymentMethods
