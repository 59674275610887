import React from 'react'
import type { FC } from 'react'

const Pix: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.982"
    height="14.171"
    viewBox="0 0 39.982 14.171"
  >
    <g
      id="Grupo_1"
      data-name="Grupo 1"
      transform="translate(-1025.881 -528.573)"
    >
      <g
        id="_Camada_"
        data-name="&lt;Camada&gt;"
        transform="translate(963.869 157.993)"
      >
        <path
          id="Caminho_1"
          data-name="Caminho 1"
          d="M449.631,433.21a.251.251,0,0,1-.251-.251V424.83a2.961,2.961,0,0,1,2.958-2.958l2.4,0a2.958,2.958,0,0,1,2.949,2.954v1.73a2.961,2.961,0,0,1-2.958,2.958h-3.391a.251.251,0,1,1,0-.5h3.391a2.46,2.46,0,0,0,2.457-2.456v-1.73a2.457,2.457,0,0,0-2.448-2.453l-2.4,0a2.46,2.46,0,0,0-2.457,2.458v8.127A.251.251,0,0,1,449.631,433.21Zm10.628-3.654a.251.251,0,0,1-.251-.251v-6.072a.861.861,0,0,0-.861-.861h-1.042a.251.251,0,0,1,0-.5h1.041a1.363,1.363,0,0,1,1.361,1.361V429.3a.251.251,0,0,1-.249.25Z"
          transform="translate(-371.167 -49.144)"
          fill="#fff"
        />
        <path
          id="Caminho_2"
          data-name="Caminho 2"
          d="M691.329,372.037l-.472-.472a.3.3,0,0,1,0-.424l.472-.472a.3.3,0,0,1,.424,0l.472.473a.3.3,0,0,1,0,.424l-.472.47a.3.3,0,0,1-.424,0Z"
          transform="translate(-602.461)"
          fill="#32bcad"
        />
        <path
          id="Caminho_3"
          data-name="Caminho 3"
          d="M752.523,429.366h-1.032a.251.251,0,1,1,0-.5h1.032a1.74,1.74,0,0,0,1.239-.513l2.414-2.415a1.051,1.051,0,0,1,1.486,0l2.406,2.406a1.741,1.741,0,0,0,1.241.514h.836a.251.251,0,0,1,0,.5h-.836a2.236,2.236,0,0,1-1.593-.66l-2.406-2.405a.55.55,0,0,0-.779,0l-2.417,2.413a2.238,2.238,0,0,1-1.593.66Zm4.395-3.9a1.046,1.046,0,0,1-.743-.308l-2.413-2.414a1.739,1.739,0,0,0-1.239-.513h-1.032a.251.251,0,1,1,0-.5h1.032a2.238,2.238,0,0,1,1.593.661l2.414,2.414a.55.55,0,0,0,.779,0l2.406-2.406a2.237,2.237,0,0,1,1.595-.659h.836a.251.251,0,0,1,0,.5h-.836a1.741,1.741,0,0,0-1.239.513l-2.406,2.405A1.046,1.046,0,0,1,756.918,425.468Z"
          transform="translate(-660.402 -49.01)"
          fill="#fff"
        />
        <path
          id="Caminho_4"
          data-name="Caminho 4"
          d="M133.621,574.438a1.929,1.929,0,0,1-1.372-.568l-1.981-1.981a.376.376,0,0,0-.52,0l-1.988,1.988a1.927,1.927,0,0,1-1.372.568H126l2.509,2.509a2.008,2.008,0,0,0,2.837,0l2.516-2.516Z"
          transform="translate(-61.312 -192.789)"
          fill="#32bcad"
        />
        <path
          id="Caminho_5"
          data-name="Caminho 5"
          d="M126.341,396.8a1.927,1.927,0,0,1,1.372.568l1.988,1.988a.368.368,0,0,0,.52,0l1.981-1.981a1.929,1.929,0,0,1,1.372-.568h.238l-2.516-2.516a2.008,2.008,0,0,0-2.837,0L125.95,396.8Z"
          transform="translate(-61.264 -22.159)"
          fill="#32bcad"
        />
        <path
          id="Caminho_6"
          data-name="Caminho 6"
          d="M74.629,482.666l-1.52-1.52a.291.291,0,0,1-.108.022H72.31a1.366,1.366,0,0,0-.962.4l-1.98,1.981a.952.952,0,0,1-1.345,0l-1.988-1.988a1.367,1.367,0,0,0-.96-.4h-.847a.287.287,0,0,1-.1-.021L62.6,482.666a2.008,2.008,0,0,0,0,2.837l1.526,1.528a.287.287,0,0,1,.1-.021h.85a1.367,1.367,0,0,0,.96-.4l1.988-1.988a.974.974,0,0,1,1.345,0l1.98,1.98a1.366,1.366,0,0,0,.962.4H73a.293.293,0,0,1,.108.022l1.52-1.52a2.008,2.008,0,0,0,0-2.837Z"
          transform="translate(0 -105.935)"
          fill="#32bcad"
        />
        <path
          id="Caminho_7"
          data-name="Caminho 7"
          d="M494.275,668.57a1.737,1.737,0,0,0-.372.057v.5a.863.863,0,0,0,.29.048c.248,0,.366-.084.366-.3s-.1-.3-.284-.3Zm-.455.92v-.981h.068l.007.042a1.965,1.965,0,0,1,.387-.062.385.385,0,0,1,.254.074.379.379,0,0,1,.112.3.345.345,0,0,1-.159.315.618.618,0,0,1-.288.064,1.035,1.035,0,0,1-.3-.045v.288Z"
          transform="translate(-413.748 -285.448)"
          fill="#fff"
        />
        <path
          id="Caminho_8"
          data-name="Caminho 8"
          d="M518.269,668.627c-.248,0-.359.078-.359.3s.109.307.359.307.358-.077.358-.3S518.517,668.627,518.269,668.627Zm.319.6a.538.538,0,0,1-.319.077.55.55,0,0,1-.321-.077.34.34,0,0,1-.128-.3.345.345,0,0,1,.128-.3.707.707,0,0,1,.64,0,.343.343,0,0,1,.128.3A.349.349,0,0,1,518.588,669.23ZM518.588,669.23Z"
          transform="translate(-436.744 -285.507)"
          fill="#fff"
        />
        <path
          id="Caminho_9"
          data-name="Caminho 9"
          d="M543.1,669.737l-.276-.595h-.006l-.273.595h-.075l-.3-.717h.092l.245.6h.006l.267-.6h.077l.274.6h.006l.24-.6h.09l-.3.717Z"
          transform="translate(-460.076 -285.958)"
          fill="#fff"
        />
        <path
          id="Caminho_10"
          data-name="Caminho 10"
          d="M576.543,668.6c-.23,0-.308.1-.322.251h.644C576.858,668.691,576.774,668.6,576.543,668.6Zm0,.682a.431.431,0,0,1-.3-.08.372.372,0,0,1-.112-.3.366.366,0,0,1,.133-.307.477.477,0,0,1,.279-.072.5.5,0,0,1,.287.069.365.365,0,0,1,.125.331h-.74c0,.148.051.278.329.278a2.118,2.118,0,0,0,.371-.042v.075a2.2,2.2,0,0,1-.376.044Z"
          transform="translate(-492.616 -285.486)"
          fill="#fff"
        />
        <path
          id="Caminho_11"
          data-name="Caminho 11"
          d="M601.51,669.248v-.718h.068l.007.042a1.258,1.258,0,0,1,.356-.062h.01v.079h-.02a1.167,1.167,0,0,0-.338.057v.6Z"
          transform="translate(-516.934 -285.469)"
          fill="#fff"
        />
        <path
          id="Caminho_12"
          data-name="Caminho 12"
          d="M614.233,668.6c-.23,0-.308.1-.322.251h.644C614.548,668.691,614.464,668.6,614.233,668.6Zm0,.682a.43.43,0,0,1-.3-.08.372.372,0,0,1-.112-.3.366.366,0,0,1,.133-.307.477.477,0,0,1,.279-.072.5.5,0,0,1,.287.069.366.366,0,0,1,.125.331h-.737c0,.148.051.278.329.278a2.118,2.118,0,0,0,.371-.042v.075a2.2,2.2,0,0,1-.376.044Z"
          transform="translate(-528.729 -285.486)"
          fill="#fff"
        />
        <path
          id="Caminho_13"
          data-name="Caminho 13"
          d="M638.365,662.217a.85.85,0,0,0-.289-.049c-.248,0-.366.084-.366.3s.1.3.284.3a1.832,1.832,0,0,0,.372-.055Zm.016.611-.007-.042a1.934,1.934,0,0,1-.388.062.327.327,0,0,1-.366-.377.341.341,0,0,1,.159-.314.6.6,0,0,1,.289-.065,1.1,1.1,0,0,1,.3.045v-.328h.084v1.019Z"
          transform="translate(-551.534 -279.049)"
          fill="#fff"
        />
        <path
          id="Caminho_14"
          data-name="Caminho 14"
          d="M676.356,662.152a1.734,1.734,0,0,0-.372.057v.493a.835.835,0,0,0,.29.05c.248,0,.366-.084.366-.3S676.543,662.152,676.356,662.152Zm.214.613a.615.615,0,0,1-.288.064,1.016,1.016,0,0,1-.321-.054l0,.034H675.9V661.79h.084v.343a2.025,2.025,0,0,1,.379-.059.384.384,0,0,1,.254.074.379.379,0,0,1,.112.3.345.345,0,0,1-.159.315Z"
          transform="translate(-588.213 -279.03)"
          fill="#fff"
        />
        <path
          id="Caminho_15"
          data-name="Caminho 15"
          d="M698.016,669.993v-.078c.042,0,.079.007.106.007a.217.217,0,0,0,.224-.148l.027-.057L698,669h.1l.321.619h.006l.3-.619h.095l-.4.806a.293.293,0,0,1-.3.194.81.81,0,0,1-.1-.007Z"
          transform="translate(-609.389 -285.938)"
          fill="#fff"
        />
        <path
          id="Caminho_16"
          data-name="Caminho 16"
          d="M733.905,663.474h-.278v.251h.279c.192,0,.264-.021.264-.125S734.071,663.474,733.905,663.474Zm-.052-.408h-.226v.255h.228c.189,0,.264-.023.264-.129s-.1-.125-.266-.125Zm.432.748a.828.828,0,0,1-.452.068h-.423v-.972h.414a.786.786,0,0,1,.413.063.206.206,0,0,1,.1.193.212.212,0,0,1-.154.216v.005c.125.029.206.092.206.23a.209.209,0,0,1-.1.2Z"
          transform="translate(-643.318 -280.103)"
          fill="#fff"
        />
        <path
          id="Caminho_17"
          data-name="Caminho 17"
          d="M762.268,669.035c-.084-.008-.167-.012-.258-.012-.146,0-.2.03-.2.1s.042.1.155.1a1.483,1.483,0,0,0,.3-.042Zm.042.3-.005-.042a1.721,1.721,0,0,1-.389.062.372.372,0,0,1-.217-.052.222.222,0,0,1,.042-.368.659.659,0,0,1,.269-.038c.075,0,.176,0,.26.01v-.013c0-.112-.074-.149-.276-.149-.078,0-.173,0-.264.013v-.145c.1-.008.214-.014.308-.014a.613.613,0,0,1,.335.067.267.267,0,0,1,.1.245v.425Z"
          transform="translate(-670.347 -285.555)"
          fill="#fff"
        />
        <path
          id="Caminho_18"
          data-name="Caminho 18"
          d="M788.729,669.242v-.4c0-.13-.067-.177-.186-.177a1.339,1.339,0,0,0-.293.046v.528h-.2v-.718h.165l.007.046a1.664,1.664,0,0,1,.383-.065.353.353,0,0,1,.245.074.277.277,0,0,1,.078.224v.439Z"
          transform="translate(-695.673 -285.462)"
          fill="#fff"
        />
        <path
          id="Caminho_19"
          data-name="Caminho 19"
          d="M813.841,669.306a.405.405,0,0,1-.267-.075.369.369,0,0,1-.114-.3.349.349,0,0,1,.146-.307.568.568,0,0,1,.315-.071c.084,0,.163.005.251.014v.153c-.072-.007-.159-.013-.228-.013-.19,0-.28.059-.28.224s.067.221.223.221a1.722,1.722,0,0,0,.3-.037v.148a1.824,1.824,0,0,1-.349.045Z"
          transform="translate(-720.02 -285.507)"
          fill="#fff"
        />
        <path
          id="Caminho_20"
          data-name="Caminho 20"
          d="M834.01,668.7c-.19,0-.274.06-.274.223s.084.233.274.233.271-.059.271-.222S834.2,668.7,834.01,668.7Zm.343.531a.6.6,0,0,1-.343.075.621.621,0,0,1-.345-.075.409.409,0,0,1,0-.606.829.829,0,0,1,.688,0,.335.335,0,0,1,.133.3A.342.342,0,0,1,834.353,669.231ZM834.353,669.231Z"
          transform="translate(-739.251 -285.507)"
          fill="#fff"
        />
        <path
          id="Caminho_21"
          data-name="Caminho 21"
          d="M872.591,663.452a.539.539,0,0,1-.349-.1.5.5,0,0,1-.152-.406.476.476,0,0,1,.2-.413.746.746,0,0,1,.412-.092c.108,0,.218.007.339.017v.174c-.1-.008-.231-.016-.331-.016-.277,0-.394.1-.394.329s.109.331.314.331a2.243,2.243,0,0,0,.433-.059v.173a2.4,2.4,0,0,1-.468.061Z"
          transform="translate(-776.198 -279.653)"
          fill="#fff"
        />
        <path
          id="Caminho_22"
          data-name="Caminho 22"
          d="M899.049,668.649c-.167,0-.23.06-.243.167h.483C899.283,668.7,899.215,668.649,899.049,668.649Zm-.03.627a.462.462,0,0,1-.3-.079.38.38,0,0,1-.115-.3.364.364,0,0,1,.135-.3.533.533,0,0,1,.314-.075.553.553,0,0,1,.311.072c.115.081.125.206.125.353h-.68c0,.109.062.18.264.18a2.569,2.569,0,0,0,.382-.037v.14A2.685,2.685,0,0,1,899.019,669.276Z"
          transform="translate(-801.599 -285.479)"
          fill="#fff"
        />
        <path
          id="Caminho_23"
          data-name="Caminho 23"
          d="M925.519,669.241v-.4c0-.13-.067-.177-.186-.177a1.339,1.339,0,0,0-.293.046v.528h-.2v-.718H925l.007.046a1.661,1.661,0,0,1,.383-.065.353.353,0,0,1,.245.074.278.278,0,0,1,.078.224v.439Z"
          transform="translate(-826.742 -285.462)"
          fill="#fff"
        />
        <path
          id="Caminho_24"
          data-name="Caminho 24"
          d="M949.59,664.764a.259.259,0,0,1-.232-.1.369.369,0,0,1-.053-.217v-.268h-.145v-.15h.145l.021-.217h.177v.217h.282v.149H949.5v.23a.333.333,0,0,0,.02.136.127.127,0,0,0,.13.067.927.927,0,0,0,.143-.014v.143a1.114,1.114,0,0,1-.207.026Z"
          transform="translate(-850.044 -280.966)"
          fill="#fff"
        />
        <path
          id="Caminho_25"
          data-name="Caminho 25"
          d="M968.79,669.259v-.718h.163l.008.046a1.232,1.232,0,0,1,.355-.065h.026v.17h-.069a1.126,1.126,0,0,0-.281.037v.529Z"
          transform="translate(-868.854 -285.48)"
          fill="#fff"
        />
        <path
          id="Caminho_26"
          data-name="Caminho 26"
          d="M984.5,669.035c-.084-.008-.167-.012-.258-.012-.146,0-.2.03-.2.1s.042.1.155.1a1.484,1.484,0,0,0,.3-.042Zm.042.3-.005-.042a1.72,1.72,0,0,1-.389.062.372.372,0,0,1-.217-.052.222.222,0,0,1,.042-.368.659.659,0,0,1,.269-.038c.075,0,.176,0,.26.01v-.013c0-.112-.074-.149-.276-.149-.078,0-.173,0-.264.013v-.145c.1-.008.214-.014.308-.014a.613.613,0,0,1,.335.067.267.267,0,0,1,.1.245v.425Z"
          transform="translate(-883.283 -285.555)"
          fill="#fff"
        />
        <path
          id="Caminho_27"
          data-name="Caminho 27"
          d="M1010.29,661.79h.2v1.019h-.2V661.79Z"
          transform="translate(-908.618 -279.03)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)

export default Pix
