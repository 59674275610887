import React from 'react'
import { Flex } from 'theme-ui'
import type { FC } from 'react'
import { usePreHeader } from 'src/contexts/PreHeaderContext/usePreHeader'

const SearchSuggestionsContainer: FC = ({ children }) => {
  const { isPreHeaderClosed } = usePreHeader()

  return (
    <Flex
      variant="suggestions"
      className="searchSuggestionsContainer"
      sx={{
        top: !isPreHeaderClosed
          ? ['160px', '125px']
          : ['calc(160px - 54px)', 'calc(125px - 40px)'],
      }}
    >
      {children}
    </Flex>
  )
}

export default SearchSuggestionsContainer
