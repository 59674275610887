import React from 'react'
import { Icon } from '@naturacosmeticos/natds-react'

import { MyBagContainer } from './styled'

export const MyBagFallback = () => {
  return (
    <MyBagContainer>
      <Icon name="outlined-finance-bag" size="semi" color="mediumEmphasis" />
      <span>0</span>
    </MyBagContainer>
  )
}
