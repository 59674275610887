/* eslint-disable no-useless-escape */
/**
 * @export
 * @description Function that returns only string without special characters
 * @param string The string passed through parameter
 * @returns Only the string
 * @example const n = onlyName("$#abc") => "abc"
 */

export function removeSpecialCharacters(value: string): string {
  return value.replace(/[`~!#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')
}
