import React from 'react'
import type { FC } from 'react'

const InstagramNonFilledIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <path
      id="iconmonstr-instagram-11"
      d="M9,1.622c2.4,0,2.688.009,3.638.053,2.439.111,3.578,1.268,3.689,3.689.043.949.052,1.234.052,3.637s-.009,2.688-.052,3.637c-.112,2.419-1.248,3.578-3.689,3.689-.95.043-1.233.052-3.638.052s-2.688-.009-3.637-.052c-2.445-.112-3.578-1.274-3.689-3.69C1.63,11.688,1.622,11.4,1.622,9s.01-2.687.052-3.637c.112-2.42,1.248-3.578,3.689-3.689C6.313,1.631,6.6,1.622,9,1.622ZM9,0C6.556,0,6.25.01,5.29.054,2.021.2.2,2.017.055,5.289.01,6.25,0,6.556,0,9s.011,2.751.054,3.711C.2,15.979,2.017,17.8,5.289,17.946,6.25,17.99,6.556,18,9,18s2.751-.01,3.711-.054c3.265-.15,5.087-1.963,5.234-5.235C17.99,11.751,18,11.444,18,9s-.01-2.75-.054-3.71C17.8,2.024,15.983.2,12.712.055,11.751.01,11.444,0,9,0ZM9,4.378A4.622,4.622,0,1,0,13.622,9,4.622,4.622,0,0,0,9,4.378ZM9,12a3,3,0,1,1,3-3A3,3,0,0,1,9,12Zm4.8-8.884A1.08,1.08,0,1,0,14.884,4.2,1.08,1.08,0,0,0,13.8,3.116Z"
    />
  </svg>
)

export default InstagramNonFilledIcon
