import { styled } from 'src/gatsby-theme-stitches/config'

export const MenuMobileContainer = styled('div', {
  position: 'sticky',
  top: 0,
  zIndex: 10,
  backgroundColor: '$onPrimary',
  padding: '.5rem 1rem',
  boxShadow: '0px 3px 6px #00000029',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '@lg': {
    display: 'none',
  },

  '.logo': {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    height: '100%',
    display: 'grid',
    placeItems: 'center',

    svg: {
      width: '96px',
    },
  },

  '> button': { all: 'unset', height: '2rem' },
})

export const IconsContainer = styled('div', {
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',

  button: { all: 'unset', height: '1.5rem' },

  i: {
    fontSize: '1.5rem',
  },
})
