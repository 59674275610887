import { styled } from 'src/gatsby-theme-stitches/config'

export const SearchBarContainer = styled('div', {
  maxWidth: '60%',

  '> div': {
    maxWidth: '100%',
    width: '42rem',
    borderRadius: '4px',
    border: '1px solid $colors$mediumEmphasis',

    '@sm': {
      margin: '0',
      maxWidth: '60vw',
      border: '1px solid $colors$primary',
    },

    input: {
      color: '#777',
      fontSize: '1rem',
      fontFamily: '$roboto',
      background: 'transparent',

      '@sm': {
        fontSize: '.875rem',
      },

      '&::placeholder': {
        color: '#777',
      },
    },
  },

  '.searchSuggestionsContainer': {
    top: '$$top',
  },
})
