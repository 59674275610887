import React from 'react'
import { Logo } from '@naturacosmeticos/natds-react'
import { Link } from 'gatsby'
import loadable from '@loadable/component'

import PreHeader from './PreHeader'
import NavigationBar from './NavigationBar'
import SearchBar from './SearchBar'
import { HeaderContainer } from './styled'
import Menu from './Menu'
import MenuMobile from './MenuMobile'
import User from './User'
import WishlistButton from './WishlistButton'
import MyBag from './MyBag'

const RepresentativeBar = loadable(() => import('./RepresentativeBar'))

const Header = () => {
  return (
    <>
      <PreHeader />

      <NavigationBar />

      <HeaderContainer>
        <div>
          <Link to="/">
            <Logo size="hugeX" color="highlight" />
          </Link>

          <SearchBar />
          <User />
          <WishlistButton />
          <MyBag />
        </div>
      </HeaderContainer>

      <MenuMobile />

      <Menu />

      <RepresentativeBar />
    </>
  )
}

export default Header
