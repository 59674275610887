/**
 * @export
 * @description Checks if an email is valid
 * @param {string} emailAddress The string passed through parameter
 * @returns {boolean} Return true or false is an email is valid
 * @example const validEmail = isValidEmail('samuel.rodrigues@acct.global') => true
 */

export function isValidEmail(emailAddress: string): boolean {
  const patternEmail = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  )

  return patternEmail.test(emailAddress)
}
