import React from 'react'
import type { FC } from 'react'

const FacebookFilledIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="18"
    viewBox="0 0 9 18"
  >
    <path
      id="iconmonstr-facebook-1"
      d="M8.25,6H6V9H8.25v9H12V9h2.732L15,6H12V4.75c0-.716.144-1,.836-1H15V0H12.144C9.447,0,8.25,1.187,8.25,3.461Z"
      transform="translate(-6)"
    />
  </svg>
)

export default FacebookFilledIcon
