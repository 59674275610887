import React from 'react'
import type { FC } from 'react'

const EloIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1998.42 765.84">
    <title>elo</title>
    <g id="Camada_2" data-name="Camada 2">
      <g id="Layer_1" data-name="Layer 1">
        <path
          className="cls-1"
          fill="#FFF"
          d="M311.54,169.17A225.33,225.33,0,0,1,383,157.64c109,0,199.95,77.4,220.79,180.28l154.48-31.5C722.8,131.58,568.28,0,383,0A382.35,382.35,0,0,0,261.57,19.67Z"
        />
        <path
          className="cls-2"
          fill="#FFF"
          d="M129.25,669.79l104.46-118.1a225.22,225.22,0,0,1-.07-337.41L129.16,96.17C50,166.32,0,268.8,0,382.92A382.09,382.09,0,0,0,129.25,669.79"
        />
        <path
          className="cls-3"
          fill="#FFF"
          d="M603.73,428.13C582.81,531,491.92,608.21,383,608.21a223.82,223.82,0,0,1-71.5-11.58l-50,149.57A383.19,383.19,0,0,0,383,765.84c185.12,0,339.61-131.36,375.2-306Z"
        />
        <path
          className="cls-4"
          fill="#FFF"
          d="M893.32,556.67a275.09,275.09,0,0,1-16.09-31c-24.46-56.79-25.63-115.56-5-172,22.72-61.87,66.1-109.24,122.16-133.35,70.48-30.34,148.43-24.36,216,15.72,42.92,24.62,73.33,62.63,96.43,116.38,3,6.88,5.53,14.23,8.06,20.47Zm140.77-245.13c-50,21.51-75.85,68.46-70.55,123.51l212-91.25C1139.11,301,1091.66,286.75,1034.09,311.54ZM1202,510.08a1.54,1.54,0,0,0-.13.12l-4.39-3c-12.65,20.53-32.39,37.16-57.31,48-47.39,20.61-91.31,15.32-122.85-12.35l-2.9,4.41s0-.09-.09-.09l-53.79,80.47a230,230,0,0,0,42.75,23.42c59.41,24.68,120.21,23.55,180.1-2.5,43.32-18.77,77.31-47.41,100.54-83.69Z"
        />
        <path
          className="cls-4"
          fill="#FFF"
          d="M1462.25,101.86V549.24l69.55,28.19-39.52,92.22-76.78-32c-17.22-7.46-29-18.87-37.84-31.77-8.48-13.14-14.84-31.17-14.84-55.45V101.86Z"
        />
        <path
          className="cls-4"
          fill="#FFF"
          d="M1642.43,435.27a126.55,126.55,0,0,1,43.48-95.47l-71.36-79.57a233.77,233.77,0,0,0-.22,350.08l71.27-79.65A126.6,126.6,0,0,1,1642.43,435.27Z"
        />
        <path
          className="cls-4"
          fill="#FFF"
          d="M1769.23,562.25a128.33,128.33,0,0,1-40.2-6.56L1694.94,657a233.53,233.53,0,0,0,74.18,12.12c113,.11,207.34-80.06,229.19-186.64L1893.56,461.1A126.9,126.9,0,0,1,1769.23,562.25Z"
        />
        <path
          className="cls-4"
          fill="#FFF"
          d="M1769.53,201.64a233.32,233.32,0,0,0-74.1,11.93L1729.2,315a128.13,128.13,0,0,1,40.22-6.49,126.85,126.85,0,0,1,124.24,101.66l104.76-21.24C1976.87,282.18,1882.61,201.72,1769.53,201.64Z"
        />
      </g>
    </g>
  </svg>
)

export default EloIcon
