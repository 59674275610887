import React from 'react'
import { Icon } from '@naturacosmeticos/natds-react'

import { WishlistButtonContainer } from './styled'

const WishlistButton = () => (
  <WishlistButtonContainer href="/wishlist">
    <Icon name="outlined-action-love" size="semi" color="mediumEmphasis" />
  </WishlistButtonContainer>
)

export default WishlistButton
