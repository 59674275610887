/**
 * @export
 * @description Function that returns only string without numbers
 * @param string The string passed through parameter
 * @returns Only the string
 * @example const n = onlyName("joao11") => "joao"
 */

export function onlyName(name: string): string {
  return name.replace(/\d/g, '')
}
