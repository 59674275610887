import React from 'react'
import type { FC } from 'react'

const CommonQuestionThirdStep: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="189.6"
    height="162.805"
    viewBox="0 0 189.6 162.805"
  >
    <g transform="translate(-65.2 -1369)">
      <path
        style={{
          fill: '#e0e0e0',
          stroke: '#fff',
          strokeWidth: '2px',
        }}
        d="M175.7,3.5H14.9A13.4,13.4,0,0,0,1.5,16.9V124.1a13.4,13.4,0,0,0,13.4,13.4H71.984a22.958,22.958,0,0,1-10.095,8.933c-4.109,2.5-8.04,4.824-8.933,8.933a8.933,8.933,0,0,0,3.037,7.415,4.467,4.467,0,0,0,3.573,1.519h71.466a4.467,4.467,0,0,0,3.127-1.34,8.933,8.933,0,0,0,3.037-7.415c-.536-4.288-4.467-6.611-8.933-8.933a22.958,22.958,0,0,1-10.095-8.933H175.7A13.4,13.4,0,0,0,189.1,124.1V16.9A13.4,13.4,0,0,0,175.7,3.5ZM14.9,12.433H175.7a4.467,4.467,0,0,1,4.467,4.467v93.8H10.433V16.9A4.467,4.467,0,0,1,14.9,12.433ZM124.065,154.2l1.965,1.161H64.569l1.965-1.161A27.336,27.336,0,0,0,81.453,137.5h27.782A27.336,27.336,0,0,0,124.065,154.2ZM175.7,128.566H14.9a4.467,4.467,0,0,1-4.467-4.467v-4.467H180.166V124.1A4.467,4.467,0,0,1,175.7,128.566Z"
        transform="translate(64.7 1366.5)"
      />
      <g transform="translate(84.736 433.638)">
        <path
          style={{
            fill: '#e0e0e0',
          }}
          d="M6.464,961.362a1.463,1.463,0,0,0,0,2.927h4.863c2.422,6,4.808,12.008,7.21,18.017l-2.21,5.32a1.464,1.464,0,0,0,1.357,2.027H42.072a1.463,1.463,0,1,0,0-2.927H19.878l1.265-3.018,23.49-1.875a1.5,1.5,0,0,0,1.311-1.128l2.927-12.682a1.531,1.531,0,0,0-1.433-1.783H15.259l-1.585-3.963a1.5,1.5,0,0,0-1.357-.915Zm9.969,7.8h29.16l-2.271,9.847-22.24,1.768ZM22.56,991.6a4.878,4.878,0,1,0,4.878,4.878A4.9,4.9,0,0,0,22.56,991.6Zm14.633,0a4.878,4.878,0,1,0,4.878,4.878,4.9,4.9,0,0,0-4.878-4.878ZM22.56,994.531a1.951,1.951,0,1,1-1.951,1.951A1.929,1.929,0,0,1,22.56,994.531Zm14.633,0a1.951,1.951,0,1,1-1.951,1.951A1.929,1.929,0,0,1,37.194,994.531Z"
        />
      </g>
      <g transform="translate(6.5 5)">
        <g transform="translate(-670 844.13)">
          <rect
            style={{
              fill: '#efefef',
            }}
            width="113"
            height="37"
            rx="10"
            transform="translate(773 568.87)"
          />
          <text
            style={{
              fill: '#3c3c3c',
              fontSize: '12px',
              fontFamily: 'Lato-Bold, Lato',
            }}
            transform="translate(805 591.87)"
          >
            <tspan x="0" y="0">
              CÓDIGO
            </tspan>
          </text>
        </g>
        <g transform="translate(201.4 1438.602)">
          <path
            style={{
              fillRule: 'evenodd',
            }}
            d="M0,0,12,6.955,7.091,8.386,10.773,13.7,9.2,14.8,5.455,9.477l-3,4.159Z"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default CommonQuestionThirdStep
