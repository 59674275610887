import React from 'react'
import type { FC } from 'react'

const SemimatteIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <g id="Semimatte" transform="translate(0 0.466)">
      <g
        id="Rectangle_4262"
        data-name="Rectangle 4262"
        transform="translate(0 -0.466)"
        fill="none"
        stroke="#fff"
        strokeWidth="1"
      >
        <rect width="19" height="19" stroke="none" />
        <rect x="0.5" y="0.5" width="18" height="18" fill="none" />
      </g>
      <path
        id="Polygon_3"
        data-name="Polygon 3"
        d="M8.5,0,17,8H0Z"
        transform="translate(18 8.534) rotate(180)"
        fill="#fff"
      />
    </g>
  </svg>
)

export default SemimatteIcon
